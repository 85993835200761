import {
    HamburgerMenuInfo,
    useAppState,
    useSetAppState,
} from "../../stores/appContext";

export const useHamburgerMenuInfo = () => {
    const state = useAppState();
    const { hamburgerMenuInfo } = state;
    const setAppState = useSetAppState();

    const hamburgerMenuSlideOpen = () => {
        const hamburgerMenuInfo: HamburgerMenuInfo = {
            hamburgerMenuIsOpen: true,
            menuAnimation: "animate-toLeftMove",
        };
        setAppState((state) => ({
            ...state,
            hamburgerMenuInfo: hamburgerMenuInfo,
        }));
    };

    const hamburgerMenuSlideClose = () => {
        const hamburgerMenuInfo: HamburgerMenuInfo = {
            hamburgerMenuIsOpen: false,
            menuAnimation: "animate-toRightMove",
        };
        setAppState((state) => ({
            ...state,
            hamburgerMenuInfo: hamburgerMenuInfo,
        }));
    };

    const hamburgerMenuClose = () => {
        const hamburgerMenuInfo: HamburgerMenuInfo = {
            hamburgerMenuIsOpen: false,
            menuAnimation: "",
        };
        setAppState((state) => ({
            ...state,
            hamburgerMenuInfo: hamburgerMenuInfo,
        }));
    };

    return {
        hamburgerMenuInfo,
        hamburgerMenuSlideOpen,
        hamburgerMenuSlideClose,
        hamburgerMenuClose,
    };
};
