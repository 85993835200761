import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, SelectBox } from "@twioku/common_components";
import { TextLabel } from "../components/TextLabel";
import { useNewAnswererInfo } from "../features/newAnswererInfo/hooks/useNewAnswererInfo";
import { Path } from "../routes/PagePath";
import { Relationship } from "../stores/appContext";
import { relationships } from "../libs/constants";

// FIXME: react-hook-form を使う
export const AnswererRelationshipPage: React.FC = () => {
    const navigate = useNavigate();
    const { newAnswererInfo, updateAnswererRelationship } =
        useNewAnswererInfo();
    const relationship = newAnswererInfo.relationship;

    const handleSelectBoxChange = (value: Relationship) => {
        updateAnswererRelationship(value);
    };

    return (
        <div className="mx-6">
            <div className="fixed bottom-[40%] left-1/2 w-10/12 -translate-x-1/2 bg-white">
                <h1 className="mx-auto w-3/4 text-center text-2xl font-bold">
                    {newAnswererInfo.name}さんとあなたとの関係性は？
                </h1>
                <div className="mt-8">
                    <TextLabel>あなたとの関係性</TextLabel>
                    <SelectBox<Relationship>
                        className="w-full"
                        options={relationships}
                        value={relationship}
                        onChange={handleSelectBoxChange}
                    />
                </div>
            </div>
            <div className="fixed bottom-0 left-1/2 w-11/12 -translate-x-1/2 bg-white">
                <Button
                    onClick={() => navigate(Path.answererbirthday)}
                    className="mb-8 mt-2 h-12 w-full"
                >
                    次へ
                </Button>
            </div>
        </div>
    );
};
