import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useNewAnswererInfo } from "../features/newAnswererInfo/hooks/useNewAnswererInfo";
import { Path } from "../routes/PagePath";
import { useCreateLineLinkOneTimePassword } from "../features/lineLink/hooks/useLineLink";
import { useAnswerer } from "../features/answerer/hooks/useAnswerer";
import { Answerer } from "../api/generated";
import { showToast } from "@twioku/common_components";
import { LineLinkShare } from "../components/LineLinkShare";
import { Button, SpinAnimation } from "@twioku/common_components";

export const SenderLineLinkConfirmPage: React.FC = () => {
    const navigate = useNavigate();
    const { newAnswererInfo } = useNewAnswererInfo();

    const { answerer, isLoading } = useAnswerer(newAnswererInfo.answererId);

    const [lineLinkOneTimePassword, setLineLinkOneTimePassword] =
        useState<string>("");
    const { createLineLinkOneTimePassword } = useCreateLineLinkOneTimePassword(
        answerer ?? ({} as Answerer)
    );

    useEffect(() => {
        if (!answerer?.answererId) return;

        // LINE連携用のURLを発行する
        createLineLinkOneTimePassword.mutate(undefined, {
            onSuccess: (lineLinkOneTimePassword) => {
                setLineLinkOneTimePassword(lineLinkOneTimePassword);
            },
            onError: () => {
                showToast(
                    "エラーが発生しました。\n管理者にお問合せください。",
                    "error"
                );
            },
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [answerer]);

    if (isLoading || createLineLinkOneTimePassword.isLoading) {
        return (
            <div className="absolute inset-0 flex items-center justify-center">
                <SpinAnimation />
            </div>
        );
    }

    return (
        <div className="flex h-full flex-col">
            <div className="px-6 pb-7 pt-5 text-center text-xl font-bold">
                <h1 className="mb-2 text-xl font-bold">
                    {newAnswererInfo.name}
                    さんのLINEアカウントにご利用開始案内リンクをお送りください！
                </h1>
                <div className="text-sm text-gray-600">
                    リンクから{newAnswererInfo.name}
                    さんにご利用案内に同意いただくことで、
                    ツイオクの利用が開始されます。
                </div>
            </div>
            <div className="mx-2 flex flex-col items-center gap-4 rounded border bg-white p-4 outline-none">
                <LineLinkShare
                    lineLinkOneTimePassword={lineLinkOneTimePassword}
                />
            </div>
            <h1 className="mx-5 my-2 text-xl font-bold">
                親御さまのご利用開始手順
            </h1>
            <div className="mx-5 mt-3 text-sm text-gray-600">
                <a
                    href={process.env.VITE_LINE_AUTH_FLOW_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary underline"
                >
                    こちら
                </a>
                からご確認ください。
            </div>
            <div className="mx-2 mt-3">
                <p className="text-sm text-gray-500">
                    ※リンクには７日間の有効期限があります。
                </p>
            </div>
            <div className="mx-4 mt-4">
                <Button
                    onClick={() => navigate(Path.root)}
                    variant="outline"
                    className="mb-8 mt-2 h-12 w-full"
                >
                    リンクを送ったので完了する
                </Button>
            </div>
        </div>
    );
};
