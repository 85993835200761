import { Configuration, DefaultApi } from "./generated";

export const DEFAULT_HEADERS = {
    "Content-Type": "application/json; charset=UTF-8",
};

export const client = new DefaultApi(
    new Configuration({
        basePath: process.env.VITE_BACKEND_API_BASE_URL,
        headers: DEFAULT_HEADERS,
        credentials: "include",
    })
);
