import * as React from "react";

type Props = {
    children: React.ReactNode;
    className?: string;
    htmlFor?: string;
};

export const TextLabel = (props: Props) => {
    const { className, children, htmlFor } = props;
    return (
        <>
            <label
                htmlFor={htmlFor}
                className={`text-base font-medium text-gray-900 ${className}`}
            >
                {children}
            </label>
        </>
    );
};
