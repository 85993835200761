import { useEffect, useState } from "react";
import { Path } from "../routes/PagePath";

export type Loadable<T> =
    | {
          isLoading: true;
          data: null;
      }
    | {
          isLoading: false;
          data: T;
      };

export const useLoadable = <T>(loader: () => Promise<T>): Loadable<T> => {
    const [loadable, setLoadable] = useState<Loadable<T>>({
        isLoading: true,
        data: null,
    });

    useEffect(() => {
        loader()
            .then((data) => {
                setLoadable({ isLoading: false, data });
            })
            .catch((e) => {
                console.error(e);
                window.location.href = Path.error;
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return loadable;
};
