import React from "react";
import { Link } from "react-router-dom";
import { ProfileImage, ProfileImageSize } from "@twioku/common_components";

type Props = {
    src?: string;
    imageSize: ProfileImageSize;
    answererId: string;
};

export const AnswererProfileImage: React.FC<Props> = ({
    src,
    imageSize,
    answererId,
}: Props) => {
    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <div onClick={handleClick}>
            <Link to={"/answerer/" + answererId}>
                <ProfileImage src={src} size={imageSize} />
            </Link>
        </div>
    );
};
