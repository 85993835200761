import React from "react";
import { Markdown } from "../components/Markdown";

const termsMarkdown = `

# 利用規約

## サービスの説明

1. ツイオク（以下「本サービス」といいます。）は、親子間で昔の思い出を共有し、コミュニケーションを取ることを目的としたWebサービスです。テキスト・音声・動画・画像をやりとりすることができます。親が受け取ったメールに返信することで、子どもが返信内容をサービス上で確認し、コメントやリアクションを残すことが可能です。
2. 当規約は本サービスを利用する場合に、適用されるものとします。

## ユーザーの定義

1. 当規約の適用範囲の前提となる利用ユーザーについて定義します。
当サービスの利用ユーザーはサービス利用者、及びサービス利用者のやり取り相手(以降、やり取り相手と表記)の両名となります。
ユーザーという表現はサービス利用者、及びやり取り相手の両名を指すものとします。

## 当規約の適用範囲

1. ユーザーは、本サービスの利用開始時点で、当規約をご一読いただくものとし、サービスの利用開始時点で、当規約に同意したものとみなします。


## ユーザーの権利と義務

1. ユーザーは、本サービスを正当な目的で使用し、全ての適用可能な法律、規制、および本利用規約を遵守する義務があります。ユーザーは、他のユーザーまたは第三者の権利を尊重し、他のユーザーまたは第三者を妨害または中傷しないことを保証します。また、ユーザーは、自分のアカウントの安全性を保つ責任を負い、パスワードを適切に管理します。

## 利用制限

1. 本サービスは個人的、非営利的な目的でのみ使用することができます。商用目的、不正な目的、または他のユーザーまたは第三者のプライバシーを侵害する目的での使用は厳しく禁止されています。また、本サービスの利用にあたり、第三者の要配慮個人情報（人種、信条、社会的身分、病歴、前科・前歴、犯罪被害情報のほか、本人に不当・偏見が生じないよう、特に配慮が必要な情報）が含まれる場合には、ユーザーは適切に当該第三者の同意を取得しておくものとします。

## 著作権や知的財産の扱い

1. 本サービス上で共有される全てのコンテンツは、サービス利用者とサービス利用者のやり取り相手、またはそれに相当する関係の者に限られ、共有されます。
またサービス利用において作られるユーザーのデータについて、所有権はデータを作成したユーザーに帰属するものとします。
つまり、サービス利用者は投稿したコメントや画像などに対する所有権を有し、やり取り相手はメールの返信内容や提出した画像・動画・音声などに対する所有権を有します。

## サービスの使用条件と、利用規約の変更に対する同意の取得

1. 本サービスの使用は、本利用規約の全ての条項に従うことを条件とします。私たちは時折、本利用規約を改訂することがあります。本利用規約の改訂が行われた場合、それは本サイト上で公表され、公表された日から有効となります。ユーザーは本サービスを引き続き使用することで、改訂された利用規約を受け入れるものとします。

## ユーザーのデータの収集、使用、共有に関するポリシー

1. 本サービスは、ユーザーのメールアドレス、氏名、性別、生年月日を収集し、ログインとサービスの利用に使用します。
また、サービスの改善のために、GoogleAnalyticsを使用してユーザーの行動を追跡します。
さらに、親子の思い出のデータを収集し、解析してサービスの改善や新機能の開発に使用する場合があります。

## プライバシーポリシー

1. 本サービスにより取得した個人情報は、関連法令、当社のプライバシーポリシー（https://redjourney.jp/privacy-policy ）の他、本サービスに適用されるプライバシーポリシー特約（以下「ツイオクプライバシーポリシー特約」といいます。）に従って適切に管理されます。当社のプライバシーポリシーとツイオクプライバシーポリシー特約が矛盾抵触する場合には、ツイオクプライバシーポリシー特約の規定が優先するものとします。

## サービスの目的と使用に関するガイドライン、そして不正使用に対する罰則

1. 本サービスは、親子間の思い出を共有し、コミュニケーションを促進するために設計されています。不正行為、不適切なコンテンツの投稿、その他の本サービスの使用目的に反する行為は厳しく禁止されています。このような行為が発見された場合、アカウントが一時的または恒久的に停止されるなど、弊社が必要と考える措置を講じる可能性があります。
それによって生じたいかなる損害についても、故意または重過失による場合を除き、一切責任を負いません。ユーザーは、弊社が本項の規定に基づいて行った措置に対して、異議を申し立てないものとします。

## ユーザーが自分のデータとプライバシーをどのように管理するかに関するガイドライン

1. ユーザーは、自分のデータとプライバシーを管理する権利を持っています。ユーザーはいつでも自分の情報を表示、修正、または削除することができます。また、ユーザーは自分のデータがどのように収集、使用、共有されるかについての詳細な情報を得る権利を持っています。

## 免責事項

1. 当社は、本サービスの不中断、安全性、エラーのない提供、または製品やサービスの欠陥が訂正されることを保証しません。特に、本サービスはメンテナンス、セキュリティー対策、または予測できない技術的な問題等により、予告なく中断する場合があります。
何らかの原因で生じた直接的、間接的、偶然的、特別、結果的または懲罰的な損害（利益の損失、ビジネスの損失、データの損失、営業の中断などを含むが、これに限定されない）について、法律が許す最大範囲内で、当社は一切の責任を負わないものとします。

2. 弊社は、弊社の都合により、本サービスおよび本アプリをいつでも任意の理由で、その機能の一部または全部の利用を制限、追加、中止または提供の変更、中断、終了することがあります。本サービスおよび本アプリの変更、利用の制限、中止または提供の中断、終了などによりユーザーに生じた損害について、弊社は、故意または重過失による場合を除き、いかなる責任も負いません。
アクセス過多、その他予期せぬ要因で表示速度の低下や障害等によって生じた損害について、弊社は、いかなる責任も負いません。

3. 弊社は、本サービスにおける一切の動作保証、使用目的への適合性の保証、使用結果に関わる的確性や信頼性の保証をせず、かついかなる内容の瑕疵担保責任も負いません。

4. 弊社は、次に掲げる場合には、当該写真等のデータを閲覧したり、保存したり、第三者に開示することができるものとします。弊社は、それによって生じたいかなる損害についても、一切責任を負いません。
    * 本サービスの技術的不具合の原因を解明し、解消するために必要な場合。
    * 裁判所や警察などの公的機関から、法令に基づく正式な照会を受けた場合。
    * 本規約に違反する行為またはそのおそれのある行為が行われ、写真等の内容を確認する必要が生じた場合。
    * 人の生命、身体および財産などに差し迫った危険があり、緊急の必要性がある場合。
    * その他本サービスを適切に運営するために必要が生じた場合。

5. 本規約の定めにかかわらず、いかなる場合においても弊社は、本サービスに関し次に掲げる損害については一切の責任を負いません。
    * 弊社の責に帰すべからざる事由から生じた損害。
    * 弊社の予見の有無に関わらず、特別の事情から生じた損害。
    * サーバーのメンテナンスなど本サービスの提供にあたって必要不可欠な行為によって生じた損害。

## サービス利用料金について

1. 本サービスのテスト期間中のサービス利用料金は無償とします。
当社は、当社の判断でテスト期間を終了して正式に運用開始することができるものとし、本サービスを正式に運用開始した場合には、以後、本サービスの利用には当社が定める利用料金をユーザーにお支払いただく必要があります。
また、当社は本サービスのサービス内容の変更、拡張等によって料金の変更の必要が発生した場合には、サービス料金を改定する事が出来るものとします。

## 準拠法、 紛争解決の方法および管轄裁判所

1. 本サービスと利用者間の紛争は、日本の法律に基づき解決されるものとします。紛争が生じた場合、利用者と本サービスは誠意を持って協議し、紛争解決を図ることを約束します。
それでもなお解決しない場合には、横浜地方裁判所を第一審の専属的合意管轄裁判所とします。

## その他

1. 本規約の一部が法令に基づいて無効と判断されても、その他の規定は有効とします。
本規約の一部があるユーザーとの関係で無効とされ、または取り消された場合でも、本規約はその他のユーザーとの関係では有効とします。

# ツイオクプライバシーポリシー特約

## 個人情報の取得方法

1. 当社は、本サービスをユーザに提供するため、以下の方法で個人情報を取得します。

    * ユーザから直接、個人情報の提供を受ける方法
    * ユーザが当社ウェブサイトまたは当社サービスを利用する際に、自動的に個人情報を記録する方法
    * 第三者から間接的に個人情報提供を受ける方法

## 個人情報の利用目的

1. 本サービスの提供、本サービスの改善、新機能の開発、およびユーザーサポートのために使用されます。


`.trim();

export const TermsPage: React.FC = () => {
    return (
        <div className="m-4">
            <Markdown>{termsMarkdown}</Markdown>
        </div>
    );
};
