import { useEffect } from "react";
import { updateLastAccessedDatetime } from "../../api/lastaccessedtime";
import { useAuth } from "../auth/hooks/useAuth";

// FIXME: tanstack query を使う
export const useLastAccessedTime = () => {
    const { csrfToken } = useAuth();

    useEffect(() => {
        if (csrfToken === "") return;
        updateLastAccessedDatetime(csrfToken);
    }, [csrfToken]);
};
