/* tslint:disable */
/* eslint-disable */
/**
 * Twioku-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PatchUsersMeAnswerersAnswererIdRequest
 */
export interface PatchUsersMeAnswerersAnswererIdRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchUsersMeAnswerersAnswererIdRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PatchUsersMeAnswerersAnswererIdRequest
     */
    receiverNickname: string;
    /**
     * 
     * @type {string}
     * @memberof PatchUsersMeAnswerersAnswererIdRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof PatchUsersMeAnswerersAnswererIdRequest
     */
    questionSendType: string;
    /**
     * 
     * @type {string}
     * @memberof PatchUsersMeAnswerersAnswererIdRequest
     */
    birthday: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchUsersMeAnswerersAnswererIdRequest
     */
    qmailFrequency: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PatchUsersMeAnswerersAnswererIdRequest
     */
    relationship: string;
}

/**
 * Check if a given object implements the PatchUsersMeAnswerersAnswererIdRequest interface.
 */
export function instanceOfPatchUsersMeAnswerersAnswererIdRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "receiverNickname" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "questionSendType" in value;
    isInstance = isInstance && "birthday" in value;
    isInstance = isInstance && "qmailFrequency" in value;
    isInstance = isInstance && "relationship" in value;

    return isInstance;
}

export function PatchUsersMeAnswerersAnswererIdRequestFromJSON(json: any): PatchUsersMeAnswerersAnswererIdRequest {
    return PatchUsersMeAnswerersAnswererIdRequestFromJSONTyped(json, false);
}

export function PatchUsersMeAnswerersAnswererIdRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchUsersMeAnswerersAnswererIdRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'receiverNickname': json['receiverNickname'],
        'email': json['email'],
        'questionSendType': json['questionSendType'],
        'birthday': json['birthday'],
        'qmailFrequency': json['qmailFrequency'],
        'relationship': json['relationship'],
    };
}

export function PatchUsersMeAnswerersAnswererIdRequestToJSON(value?: PatchUsersMeAnswerersAnswererIdRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'receiverNickname': value.receiverNickname,
        'email': value.email,
        'questionSendType': value.questionSendType,
        'birthday': value.birthday,
        'qmailFrequency': value.qmailFrequency,
        'relationship': value.relationship,
    };
}

