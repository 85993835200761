export const startUsingServiceMailText = (
    answererName: string,
    receiverNickname: string,
    qmailFrequencyJapanese: (string | undefined)[]
) => {
    const text = `${answererName}さん、はじめまして。
    記憶を共有するサービス「ツイオク」に、${receiverNickname}さんから招待が届いています。
    本メールにて、ご利用開始のご案内を差し上げました。
    これからこのメールアドレスに毎週${qmailFrequencyJapanese.join(
        ","
    )}にメールが届きます。
    メールにはひとつだけ問いかけが書いてありますので、その問いかけに沿って些細な日常や、懐かしい記憶をふりかえってください。
    ふりかえったことを、メールにご返信いただくことで、${receiverNickname}さんに届き、記憶が共有されます。
    また、${answererName}さんが共有した記憶に、${receiverNickname}さんがリアクションをしたときには、その内容がメールで届きますので、どうぞ楽しみにしていてください。
    そのメールに返信することで${receiverNickname}さんとやりとりができますので、ぜひこちらもお試しください。
    それでは、これからツイオクからのメールを定期的にご確認いただき、問いかけに対してメールをご返信いただきますよう、よろしくお願いします。

    ※
    本サービスの利用にあたっては、利用規約が適用されるものとします。
    このメールに返信することにより、あなたはこのサービスの利用規約に同意したことになります。
    利用規約に同意いただける場合は、このメールにご返信をお願いします。
    メールにご返信いただくことで初めて、サービスの利用が開始されます。
    メールにご返信されない場合、サービスの利用が開始されることはありません。
    `;

    return text;
};
