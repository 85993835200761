/* tslint:disable */
/* eslint-disable */
/**
 * Twioku-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Todo
 */
export interface Todo {
    /**
     * 
     * @type {string}
     * @memberof Todo
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof Todo
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Todo
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof Todo
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof Todo
     */
    createdAt: string;
}

/**
 * Check if a given object implements the Todo interface.
 */
export function instanceOfTodo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "createdAt" in value;

    return isInstance;
}

export function TodoFromJSON(json: any): Todo {
    return TodoFromJSONTyped(json, false);
}

export function TodoFromJSONTyped(json: any, ignoreDiscriminator: boolean): Todo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'title': json['title'],
        'description': json['description'],
        'label': !exists(json, 'Label') ? undefined : json['Label'],
        'createdAt': json['createdAt'],
    };
}

export function TodoToJSON(value?: Todo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'title': value.title,
        'description': value.description,
        'Label': value.label,
        'createdAt': value.createdAt,
    };
}

