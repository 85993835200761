import { useAuth } from "../../auth/hooks/useAuth";
import { uploadMyProfileImage, changeEmail } from "../../../api/user";
import { useCallback, useState } from "react";

// FIXME: 用途ごとに hook を分割し、且つ tanstack query を使う
export const useUser = () => {
    const { csrfToken } = useAuth();
    const mbUnitFileSizeLimit = 5;
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const uploadProfileImage = async (
        file: File
    ): Promise<{
        isSuccess: boolean;
        message: string;
        uploadFilePath: string;
    }> => {
        const validatedResult = validateProfileImage(file);
        if (!validatedResult.isSuccess) {
            return validatedResult;
        }

        try {
            const fileImagePath = await uploadMyProfileImage(file, csrfToken);
            return {
                isSuccess: true,
                message: "プロフィール画像を保存しました。",
                uploadFilePath: fileImagePath,
            };
        } catch {
            return {
                isSuccess: false,
                message: "予期しないエラーが発生しました。",
                uploadFilePath: "",
            };
        }
    };

    const validateProfileImage = (file: File) => {
        const allowExtensions = ".(jpeg|jpg|png|gif)$";
        if (!file.name.match(allowExtensions)) {
            return {
                isSuccess: false,
                message: "jpeg, jpg, png, gifファイルを選択してください。",
                uploadFilePath: "",
            };
        }

        const sizeLimit = 1024 * 1024 * mbUnitFileSizeLimit;
        if (file.size > sizeLimit) {
            return {
                isSuccess: false,
                message: "ファイルサイズは5MB以下にしてください。",
                uploadFilePath: "",
            };
        }

        return {
            isSuccess: true,
            message: "",
            uploadFilePath: "",
        };
    };

    const updateEmail = useCallback(
        async (email: string) => {
            setIsLoading(true);
            await changeEmail(email, csrfToken).finally(() => {
                setIsLoading(false);
            });
        },
        [csrfToken]
    );

    return {
        isLoading,
        uploadProfileImage,
        validateProfileImage,
        updateEmail,
    };
};
