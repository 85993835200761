import React from "react";
import { Frequency, QuestionSendType } from "../../../stores/appContext";
import { ConfirmContentItem } from "./ConfirmContentItem";
import {
    isDate,
    parseToDate,
    sortFrequencyJapanese,
    toFormatString,
} from "../../../libs/helper";
import { relationships } from "../../../libs/constants";

type Props = {
    name: string;
    birthday: string;
    relationship: string;
    receiverNickname: string;
    questionSendType: QuestionSendType;
    email: string;
    qmailSendFrequency: Frequency[];
    isEdit?: boolean;
};

export const AnswererDetail: React.FC<Props> = ({
    name,
    birthday,
    relationship,
    receiverNickname,
    questionSendType,
    email,
    qmailSendFrequency,
}) => {
    const qmailSendFrequencyJapanese =
        sortFrequencyJapanese(qmailSendFrequency);

    const relationshipJapanese = relationships.find(
        (item) => item.value === relationship
    )?.label;

    const formattedBirthday = birthday.replaceAll("-", "");

    const questionSendName = () => {
        if (questionSendType === "Email") {
            return "メール";
        }
        if (questionSendType === "Line") {
            return "LINE";
        }
        return "";
    };

    return (
        <div className="flex-1 overflow-y-auto">
            <ConfirmContentItem label="お名前" text={name} />
            <ConfirmContentItem
                label="あなたとの関係性"
                text={relationshipJapanese ? relationshipJapanese : ""}
            />
            <ConfirmContentItem
                label="お誕生日"
                text={
                    isDate(formattedBirthday)
                        ? toFormatString(
                              parseToDate(formattedBirthday),
                              true,
                              "/"
                          )
                        : ""
                }
            />
            <ConfirmContentItem
                label="あなたを呼ぶニックネーム"
                text={receiverNickname}
            />
            <ConfirmContentItem
                label="やり取りの方法"
                text={questionSendName()}
            />
            {questionSendType === "Email" && (
                <ConfirmContentItem label="メールアドレス" text={email} />
            )}
            <ConfirmContentItem
                label="問いかけメールの送信頻度"
                text={qmailSendFrequencyJapanese.join(",")}
            />
        </div>
    );
};
