import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAnswerers } from "../features/answerer/hooks/useAnswerers";
import { ProfileImage } from "@twioku/common_components";
import { SpinAnimation } from "@twioku/common_components";
import { Button } from "@twioku/common_components";
import { Path } from "../routes/PagePath";
import AnserwerEmpty from "../assets/answerer_empty.svg";

export const AnswerersPage: React.FC = () => {
    const { answerers, isLoading } = useAnswerers();
    const navigate = useNavigate();

    if (isLoading)
        return (
            <div className="absolute inset-0 flex items-center justify-center">
                <SpinAnimation />
            </div>
        );

    return (
        <>
            {answerers.length > 0 ? (
                answerers.map((answerer) => {
                    return (
                        <div key={answerer.answererId} className="border-b-2">
                            <Link
                                to={"/answerer/" + answerer.answererId}
                                className="mx-5 flex justify-between py-3"
                            >
                                <div className="flex">
                                    <ProfileImage
                                        src={answerer.profileImagePath}
                                        size="small"
                                    />
                                    <span className="my-auto block px-3">
                                        {answerer.name}
                                    </span>
                                </div>
                                {!answerer.isPrivacyAndUsageTermsAgreed && (
                                    <div
                                        className={`my-auto flex h-6 w-auto justify-center rounded-full bg-yellow-100 px-2`}
                                    >
                                        <div className="my-auto text-sm text-yellow-500">
                                            ※開始前
                                        </div>
                                    </div>
                                )}
                            </Link>
                        </div>
                    );
                })
            ) : (
                <>
                    <h1 className="mx-auto mt-32 w-3/4 text-center text-2xl font-bold">
                        まだ誰ともやりとりをしていません…
                    </h1>

                    <div className="mt-40 flex flex-col items-center">
                        <img src={AnserwerEmpty} alt="AnserwerEmptyImg" />
                    </div>

                    <div className="fixed bottom-0 left-1/2 w-11/12 -translate-x-1/2 bg-white">
                        <Button
                            onClick={() => navigate(Path.tutorial)}
                            className="mb-6 mt-6 h-12 w-full"
                        >
                            やり取り相手を追加する
                        </Button>
                    </div>
                </>
            )}
        </>
    );
};
