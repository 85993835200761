import React from "react";
import { VideoPlayer } from "./VideoPlayer";

type Props = {
    srcList: string[] | undefined;
};

export const VideoList: React.FC<Props> = ({ srcList }: Props) => {
    return (
        <>
            {srcList
                ? srcList.map((src, index) => {
                      return (
                          <div key={index} className="mt-2">
                              <VideoPlayer className="mt-2" src={src} />
                          </div>
                      );
                  })
                : null}
        </>
    );
};
