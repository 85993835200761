/* tslint:disable */
/* eslint-disable */
/**
 * Twioku-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostAuthPasswordResetRequest
 */
export interface PostAuthPasswordResetRequest {
    /**
     * 
     * @type {string}
     * @memberof PostAuthPasswordResetRequest
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof PostAuthPasswordResetRequest
     */
    confirmationCode: string;
    /**
     * 
     * @type {string}
     * @memberof PostAuthPasswordResetRequest
     */
    newPassword: string;
}

/**
 * Check if a given object implements the PostAuthPasswordResetRequest interface.
 */
export function instanceOfPostAuthPasswordResetRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userName" in value;
    isInstance = isInstance && "confirmationCode" in value;
    isInstance = isInstance && "newPassword" in value;

    return isInstance;
}

export function PostAuthPasswordResetRequestFromJSON(json: any): PostAuthPasswordResetRequest {
    return PostAuthPasswordResetRequestFromJSONTyped(json, false);
}

export function PostAuthPasswordResetRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostAuthPasswordResetRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userName': json['userName'],
        'confirmationCode': json['confirmationCode'],
        'newPassword': json['newPassword'],
    };
}

export function PostAuthPasswordResetRequestToJSON(value?: PostAuthPasswordResetRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userName': value.userName,
        'confirmationCode': value.confirmationCode,
        'newPassword': value.newPassword,
    };
}

