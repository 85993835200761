export const Path = {
    root: "/",
    signin: "/signin",
    signup: "/signup",
    signupResult: "/signup/result",
    terms: "/terms",
    passwordResetSendConfirmationCode: "/password/reset/send-confirmation-code",
    passwordResetSendConfirmationCodeCompleted:
        "/password/reset/send-confirmation-code/completed",
    passwordResetNewPassword: "/password/reset/new-password",
    passwordResetNewPasswordCompleted: "/password/reset/new-password/completed",
    answerers: "/answerers",
    profile: "/profile",
    tutorial: "/tutorial",
    answerer: "/answerer",
    answererDetail: "/answerer/:id",
    answererDetailQmailSendFrequency: "/answerer/:id/qmailsendfrequency",
    answerername: "/answerer/name",
    answererrelationship: "/answerer/relationship",
    answererbirthday: "/answerer/birthday",
    receivernickname: "/answerer/receivernickname",
    answererQuestionSendType: "/answerer/questionSendType",
    answereremail: "/answerer/email",
    answererLinePreparation: "/answerer/line/preparation",
    memorydetail: "/memory/:id",
    qmailsendfrequency: "/answerer/qmailsendfrequency",
    answererconfirm: "/answerer/confirm",
    answerercomplete: "/answerer/complete",
    senderEmailConfirm: "/answerer/senderemail/confirm",
    senderLineLinkConfirm: "/answerer/senderlinelink/confirm",
    sendEmailCompleted: "/answerer/sendEmail/completed",
    useStartMailRemind: "/useStartMailRemind",
    emailConfirm: "/email/confirm",
    error: "/error",
    notFound: "/404",
};
