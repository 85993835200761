import { useEffect, useState } from "react";
import { Memory } from "../../../api/generated";
import { getMemories, incrementMemoryLikedCount } from "../../../api/memory";
import { useAppState, useSetAppState } from "../../../stores/appContext";
import { useAuth } from "../../auth/hooks/useAuth";
import { Path } from "../../../routes/PagePath";
import { getAnswerers } from "../../../api/answerer";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { CacheKey } from "../../../constants/CacheKey";

// FIXME: 用途ごとに hook を分割し、且つ tanstack query を使う
export const useMemories = () => {
    const setAppState = useSetAppState();
    const state = useAppState();
    const { tempLikedMemoryIds } = state;
    const { csrfToken } = useAuth();
    const [hasNextMemory, setHasNextMemory] = useState<boolean>(false);
    const limit = 10;
    const navigate = useNavigate();

    // 初回記憶取得処理
    const getInitMemoryWithPagenation = async (): Promise<Memory[]> => {
        // 初回呼び出し時は最初のページを取得してくる。
        const { memories: memoryList, hasNextPage } = await getMemories(
            undefined,
            limit
        );
        await answererValidateAndRedirect(memoryList);
        setHasNextMemory(hasNextPage);
        return memoryList;
    };

    // 回答者がいない or 利用規約に同意していない回答者が1人以上いるときに別ページに遷移させる
    const answererValidateAndRedirect = async (
        memoryList: Memory[]
    ): Promise<void> => {
        // 記憶件数が1件でもある場合は何もしない
        if (memoryList.length >= 1) {
            return;
        }

        const answerers = await getAnswerers();
        if (answerers?.length === 0) {
            navigate(Path.answerers);
            return;
        }

        const termsNotAgreedAnswerers = answerers.filter(
            (x) => !x.isPrivacyAndUsageTermsAgreed
        );
        // 利用規約に同意していない回答者が1人でもいる場合、利用規約同意リマインドページに遷移する。
        if (termsNotAgreedAnswerers?.length >= 1) {
            navigate(Path.useStartMailRemind);
            return;
        }
    };

    const queryClient = useQueryClient();
    const {
        data: memories,
        isLoading: isMemoriesLoading,
        isError,
    } = useQuery<Memory[]>({
        queryKey: CacheKey.memories,
        queryFn: getInitMemoryWithPagenation,
    });
    const {
        mutate,
        isLoading: isNextMemoriesLoading,
        isError: isErrorGetNextMemories,
    } = useMutation({
        mutationFn: () => getNextMemoriesWithPagenation(),
        onSuccess(data) {
            queryClient.setQueryData(CacheKey.memories, [
                ...(memories ?? []),
                ...data.memories,
            ]);
            setHasNextMemory(data.hasNextPage);
        },
    });

    const getNextMemoriesWithPagenation = async (): Promise<{
        memories: Memory[];
        hasNextPage: boolean;
    }> => {
        if (!memories) return { memories: [], hasNextPage: false };
        const response = await getMemories(memories.slice(-1)[0]?.id, limit);
        return {
            memories: response.memories,
            hasNextPage: response.hasNextPage,
        };
    };

    const incrementLikedCount = (memoryId: string) => {
        if (!memories) return;
        if (tempLikedMemoryIds.includes(memoryId)) return;

        memories.map((memory) => {
            if (memory.id === memoryId) ++memory.likedCount;
            return memory;
        });

        setAppState((state) => ({
            ...state,
            tempLikedMemoryIds: [...state.tempLikedMemoryIds, memoryId],
        }));

        incrementMemoryLikedCount(memoryId, csrfToken).catch(() => {
            if (!memories) return;

            // 異常終了の場合、イイねインクリメント処理実行前の状態に戻す。
            memories.map((memory) => {
                if (memory.id === memoryId) --memory.likedCount;
                return memory;
            });
            setAppState((prevState) => {
                const newTempLikedMemoryIds =
                    prevState.tempLikedMemoryIds.filter((n) => n !== memoryId);
                return {
                    ...prevState,
                    tempLikedMemoryIds: [...newTempLikedMemoryIds],
                };
            });
        });
    };

    useEffect(() => {
        // エラーが発生した時にエラーページに遷移させる
        if (isError || isErrorGetNextMemories) {
            window.location.href = Path.error;
        }
    }, [isError, isErrorGetNextMemories]);

    useEffect(() => {
        if (!memories) return;
        setHasNextMemory(memories.length % limit === 0);
        answererValidateAndRedirect(memories);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        memories: memories ?? [],
        isMemoriesLoading,
        incrementLikedCount,
        tempLikedMemoryIds,
        getNextMemoriesWithPagenation: mutate,
        isNextMemoriesLoading,
        hasNextMemory,
    };
};
