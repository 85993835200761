import { useEffect, useMemo, useState } from "react";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import { Path } from "../../../routes/PagePath";

const useLocationChange = () => {
    const [pathHistory, setPathHistory] = useState<Array<string>>([]);
    const [goBackExecutionFlag, setGoBackExecutionFlag] =
        useState<boolean>(false);

    const location = useLocation();
    const path = useMemo(() => {
        return location.pathname;
    }, [location]);
    const navigation = useNavigate();
    const rootMatch = useMatch(Path.root);

    useEffect(() => {
        // 戻る処理で遷移してきたパスは履歴に追加を行わない。
        if (goBackExecutionFlag) {
            setGoBackExecutionFlag(false);
            return;
        }

        // HOME画面(記憶一覧画面)に遷移した場合、遷移履歴をクリアする。
        if (rootMatch) {
            setPathHistory([Path.root]);
            return;
        }

        // 前の遷移履歴のPathが来たときは履歴に追加を行わない。
        if (
            pathHistory.length >= 1 &&
            pathHistory[pathHistory.length - 1] === path
        ) {
            return;
        }

        pathHistory.push(path);
        setPathHistory([...pathHistory]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path]);

    const goBack = () => {
        setGoBackExecutionFlag(true);

        // 遷移履歴がない場合は想い出一覧画面に遷移する。
        if (pathHistory.length === 0) {
            navigation(Path.root);
            return;
        }

        // 戻る処理実行時には現在のページを履歴から削除する。
        const list = [...pathHistory];
        list.pop();
        setPathHistory(list);

        navigation(list[list.length - 1] ?? Path.root);
    };

    return {
        goBack: goBack,
        hasPathHistory: Boolean(pathHistory.length > 1),
        currentPath: pathHistory[pathHistory.length - 1] ?? Path.root,
    };
};

export default useLocationChange;
