import React, { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

type Props = {
    name?: string;
    placeholder?: string;
    value: string;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const PasswordInput = (props: Props) => {
    const { name, value, handleChange, placeholder } = props;

    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <div className="grid w-full grid-cols-9">
                <input
                    className={`col-span-8 h-12 rounded-l-lg rounded-r-none border-y border-l px-2 text-base`}
                    type={showPassword ? "text" : "password"}
                    name={name}
                    onChange={handleChange}
                    value={value}
                    placeholder={placeholder}
                />
                <div
                    className="col-span-1 flex h-12 cursor-pointer items-center justify-center rounded-r-lg border-y border-r text-base"
                    onClick={handleTogglePasswordVisibility}
                >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                </div>
            </div>
        </>
    );
};
