import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@twioku/common_components";
import { useNewAnswererInfo } from "../features/newAnswererInfo/hooks/useNewAnswererInfo";
import { startUsingServiceMailText } from "../libs/EmailTemplates/startUsingServiceText";
import { sortFrequencyJapanese } from "../libs/helper";
import { Path } from "../routes/PagePath";

// FIXME: react-hook-form を使う
export const SenderEmailContentsConfirmPage: React.FC = () => {
    const navigate = useNavigate();
    const {
        newAnswererInfo,
        isError,
        errorMessage,
        sendVerifyEmail,
        isSendVerifyEmailLoading,
    } = useNewAnswererInfo();
    const sendEmailButtonHandleClick = async () => {
        const result = await sendVerifyEmail();
        if (result) navigate(Path.sendEmailCompleted);
    };

    const frequencyJapanese = sortFrequencyJapanese(
        newAnswererInfo.qmailSendFrequency
    );

    const contentLines = startUsingServiceMailText(
        newAnswererInfo.name,
        newAnswererInfo.receiverNickname,
        frequencyJapanese
    ).split("\n");

    return (
        <div className="flex h-full flex-col">
            <div className="px-6 pb-7 pt-5 text-center text-xl font-bold">
                <h1 className="mb-2 text-xl font-bold">
                    メールアドレス確認のため、以下の案内メールを
                    {newAnswererInfo.name}さんに送信してもよろしいですか？
                </h1>
                <div className="text-sm text-gray-600">
                    ※このメールに{newAnswererInfo.name}
                    さんがご返信頂くことで、当サービスの利用に同意いただいたものとします。
                </div>
            </div>
            <hr />
            <div className="flex-1 overflow-y-auto pb-32">
                <div className="w-full break-words border-gray-200 px-6 pb-2 pt-1">
                    <p className="py-1 text-sm text-gray-400">件名：</p>
                    <p className="text-gray-500">
                        【ツイオク】{newAnswererInfo.receiverNickname}
                        さんに記憶を少しずつ共有しましょう。
                    </p>
                </div>
                <hr />
                <div
                    className={`w-full break-words border-gray-200 px-6 pt-1 ${
                        isError && errorMessage ? "pb-10" : "pb-2"
                    }`}
                >
                    <p className="py-1 text-sm text-gray-400">本文：</p>
                    <p className="text-gray-500">
                        {contentLines.map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                {index !== contentLines.length - 1 && <br />}
                            </React.Fragment>
                        ))}
                    </p>
                </div>
            </div>
            <div className="fixed bottom-0 left-1/2 w-11/12 -translate-x-1/2 bg-white">
                <Button
                    onClick={sendEmailButtonHandleClick}
                    className="mb-8 mt-2 h-12 w-full"
                    disabled={isSendVerifyEmailLoading}
                    loading={isSendVerifyEmailLoading}
                >
                    確認してメールを送信
                </Button>
            </div>
        </div>
    );
};
