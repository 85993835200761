export const getDaysToReply = (respondDate: Date, sendDate: Date): string => {
    // 時、分、秒、ミリ秒をクリアして、日付だけの情報をDateオブジェクトを作成
    const clearTime = (date: Date) => new Date(date.setHours(0, 0, 0, 0));

    // 返信までにかかった日数を計算
    const diffDay: number =
        (clearTime(respondDate).getTime() - clearTime(sendDate).getTime()) /
        (1000 * 60 * 60 * 24);

    if (diffDay < 0) return "";

    const strDiffDay = diffDay === 0 ? "当日返信" : `${diffDay}日`;
    return `返信までにかかった日数：${strDiffDay}`;
};
