import { client, DEFAULT_HEADERS } from "./defaultClient";

export const createLineLinkOneTimePassword = async (
    answererId: string,
    csrfToken: string
): Promise<string> => {
    const response = await client.postLineAccountLinkOneTimePassword(
        {
            postLineAccountLinkOneTimePasswordRequest: {
                answererId,
            },
        },
        {
            mode: "cors",
            headers: {
                ...DEFAULT_HEADERS,
                "X-CSRF-Token": csrfToken,
            },
        }
    );

    return response.oneTimePassword;
};
