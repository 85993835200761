import {
    AppState,
    initialState,
    AppStateContext,
    SetAppStateContext,
} from "../stores/appContext";
import { useState } from "react";

export function AppStateProvider(props: {
    initialState?: AppState;
    children: React.ReactNode;
}): JSX.Element {
    const [state, setState] = useState<AppState>(
        props.initialState ?? initialState
    );
    return (
        <AppStateContext.Provider value={state}>
            <SetAppStateContext.Provider value={setState}>
                {props.children}
            </SetAppStateContext.Provider>
        </AppStateContext.Provider>
    );
}
