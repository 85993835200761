import React from "react";
import ReactMarkdown, { Options } from "react-markdown";
import remarkGfm from "remark-gfm";

export type MarkdownProps = Options;

// NOTE: レンダリングされた HTML に対して適用するスタイルは `src/index.scss` に記述する
export const Markdown: React.FC<MarkdownProps> = (props) => {
    return (
        <div className="markdown">
            <ReactMarkdown remarkPlugins={[remarkGfm]} {...props} />
        </div>
    );
};
