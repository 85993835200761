import React from "react";
import { useNavigate } from "react-router-dom";
import { Memory } from "../../../api/generated";
import CommentIconImg from "../../../assets/comment_icon.svg";
import { LikedButton } from "../../memory/components/LikedButton";
import { toFormatString } from "../../../libs/helper";
import { VideoList } from "../../../components/VideoList";
import { AudioMemoryItem } from "./AudioMemoryItem";
import { getDaysToReply } from "../libs/memoryHelper";
import { AnswererProfileImage } from "../../../components/AnswererProfileImage";
import { PartialLinkText, ImageList } from "@twioku/common_components";

type Props = {
    memory: Memory;
    incrementLikeCount: (memoryId: string) => void;
    tempLikedMemoryIds: string[];
};

export const MemoryItem: React.FC<Props> = (props: Props) => {
    const navigate = useNavigate();
    const { memory, incrementLikeCount, tempLikedMemoryIds } = props;
    const handleClickLikedButton = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        incrementLikeCount(memory.id);
    };
    const handleClickCommentButton = (
        e: React.MouseEvent<HTMLButtonElement>
    ) => {
        e.preventDefault();
        e.stopPropagation();
        navigate("/memory/" + memory.id, { state: { willComment: true } });
    };
    const daysToReplay = (): string => {
        if (!memory.respondDate || !memory.sendDate) return "";

        const respondDate = new Date(memory.respondDate);
        const sendDate = new Date(memory.sendDate);
        return getDaysToReply(respondDate, sendDate);
    };

    return (
        <>
            <article
                className={`relative flex ${
                    (memory.isUnread || memory.hasUnreadComment) &&
                    "bg-yellow-600 bg-opacity-10"
                }`}
            >
                <section className="m-4 w-full">
                    <header>
                        <h2 className="border-l-4 border-primary px-3 text-lg">
                            {memory.title}
                        </h2>
                        <div className="mt-6 flex items-center justify-between">
                            <div className="mb-2 flex">
                                <AnswererProfileImage
                                    src={memory.answererProfileImagePath}
                                    imageSize="medium"
                                    answererId={memory.answererId}
                                />
                                <div className="ml-2">
                                    <p className="text-sm">
                                        {memory.answererName}
                                    </p>
                                    <p className="mt-1 text-sm text-gray-500">
                                        返信日時：
                                        {memory?.respondDate
                                            ? toFormatString(
                                                  new Date(memory.respondDate),
                                                  false,
                                                  "/"
                                              )
                                            : ""}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </header>
                    {memory.memoryVoiceAudioPath ? (
                        <AudioMemoryItem
                            profileImagePath={memory.answererProfileImagePath}
                            voiceAudioPath={memory.memoryVoiceAudioPath}
                        />
                    ) : (
                        <PartialLinkText
                            className={`whitespace-pre-line break-all pt-1 text-base ${
                                memory.isUnread ? "font-bold" : ""
                            }`}
                        >
                            {memory.respondContent}
                        </PartialLinkText>
                    )}
                    <ImageList srcList={memory.memoryImagePaths ?? []} />
                    <VideoList srcList={memory.memoryVideoPaths} />
                    <div className="mt-6 flex items-center justify-between">
                        <p className="text-xs text-gray-500">
                            {daysToReplay()}
                        </p>
                        <div className="relative flex items-center">
                            <button
                                className="ml-2"
                                onClick={handleClickCommentButton}
                            >
                                {memory.hasUnreadComment && (
                                    <div className="absolute left-6 h-2 w-2 rounded-full bg-red-500"></div>
                                )}
                                <img
                                    src={CommentIconImg}
                                    className="w-6"
                                    alt="comment icon"
                                />
                            </button>
                            <div className="ml-2 text-gray-700">
                                {memory.comments.length}
                            </div>
                            <LikedButton
                                likedCount={memory.likedCount}
                                disabled={tempLikedMemoryIds.includes(
                                    memory.id
                                )}
                                handleClick={handleClickLikedButton}
                                className="ml-2"
                            />
                        </div>
                    </div>
                </section>
            </article>
            <hr />{" "}
            {/* FIXME: https://tailwindcss.com/docs/divide-width とかでできそうだけどなんかできなかった */}
        </>
    );
};
