import { MemoryItem } from "./MemoryItem";
import { useNavigate, Link } from "react-router-dom";
import { useMemories } from "../hooks/useMemories";
import { SpinAnimation } from "@twioku/common_components";
import UndrawMailbox from "../../../assets/undraw_mailbox_re_dvds.svg";
import { Memory } from "../../../api/generated";
import { useAppState, useSetAppState } from "../../../stores/appContext";
import { useEffect, useRef } from "react";

export const MemoriesList: React.FC = () => {
    const appState = useAppState();
    const setAppState = useSetAppState();

    const ref = useRef<HTMLLIElement>(null);
    useEffect(() => {
        if (appState?.goBackMemoryId) {
            if (!ref.current) return;
            ref.current.scrollIntoView({
                behavior: "auto",
                block: "center",
            });
            setAppState((state) => ({
                ...state,
                goBackMemoryId: undefined,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const navigate = useNavigate();
    const {
        memories,
        isMemoriesLoading,
        incrementLikedCount,
        tempLikedMemoryIds,
        getNextMemoriesWithPagenation,
        isNextMemoriesLoading,
        hasNextMemory,
    } = useMemories();

    const gotoMemoryDetail = (memory: Memory) => {
        memory.hasUnreadComment = false;
        memory.isUnread = false;
        setAppState((state) => ({
            ...state,
            goBackMemoryId: memory.id,
        }));
        navigate("/memory/" + memory.id);
    };

    const getNextMemoryLink = () => {
        if (isNextMemoriesLoading)
            return (
                <>
                    <div className="my-2 flex justify-center">
                        <SpinAnimation />
                    </div>
                </>
            );

        if (hasNextMemory)
            return (
                <>
                    <div className="flex justify-center">
                        <Link
                            to="#"
                            className="my-6 text-primary underline"
                            onClick={() => {
                                getNextMemoriesWithPagenation();
                            }}
                        >
                            更に読み込む
                        </Link>
                    </div>
                </>
            );

        return <></>;
    };

    if (isMemoriesLoading)
        return (
            <div className="absolute inset-0 flex items-center justify-center">
                <SpinAnimation />
            </div>
        );

    return (
        <>
            {memories.length === 0 ? (
                <div className="flex flex-col items-center">
                    <h1 className="mt-12 p-8">
                        まだ回答がされていないようです…もうしばらくお待ち下さい
                    </h1>
                    <img
                        src={UndrawMailbox}
                        width={250}
                        height={250}
                        className="mt-16"
                    />
                </div>
            ) : (
                <>
                    <ul>
                        {memories.map((memory, index) => (
                            <li
                                key={index}
                                onClick={() => gotoMemoryDetail(memory)}
                                ref={
                                    memory.id === appState.goBackMemoryId
                                        ? ref
                                        : null
                                }
                            >
                                <div>
                                    <MemoryItem
                                        memory={memory}
                                        incrementLikeCount={() =>
                                            incrementLikedCount(memory.id)
                                        }
                                        tempLikedMemoryIds={tempLikedMemoryIds}
                                    />
                                </div>
                            </li>
                        ))}
                    </ul>
                    {getNextMemoryLink()}
                </>
            )}
        </>
    );
};
