import React from "react";
import { Link } from "react-router-dom";
import { Path } from "../routes/PagePath";
import { ProfileImage, ProfileImageSize } from "@twioku/common_components";

type Props = {
    src?: string;
    imageSize: ProfileImageSize;
};

export const UserProfileImage: React.FC<Props> = ({
    src,
    imageSize,
}: Props) => {
    return (
        <Link to={Path.profile}>
            <ProfileImage src={src} size={imageSize} />
        </Link>
    );
};
