import React from "react";

type Props = {
    label: string;
    value: string;
    name: string;
    handleChangeText: (event: React.ChangeEvent<HTMLInputElement>) => void;
    errorMessage: string;
};

export const ConfirmContentInput: React.FC<Props> = (props: Props) => {
    const { label, value, name, handleChangeText, errorMessage } = props;
    return (
        <div className="w-full break-words border-b-[1px] border-gray-200 pt-1">
            <div className="px-3">
                <span className="block text-xs text-gray-400">{label}</span>
                <div className="my-2 flex justify-between">
                    <input
                        value={value}
                        name={name}
                        onChange={handleChangeText}
                        className="block w-full"
                        placeholder="ex)xxxx@example.com"
                    />
                </div>
                <span className="block text-center text-base text-red-500">
                    {errorMessage}
                </span>
            </div>
        </div>
    );
};
