import { useSearchParams } from "react-router-dom";
import MailboxImage from "../assets/undraw_mailbox_re_dvds.svg";

export const SignupResultPage = () => {
    const [searchParams] = useSearchParams();
    const email = decodeURIComponent(searchParams.get("email") ?? "");

    return (
        <div className="mx-6 pt-20">
            <section>
                <h1 className="text-center text-xl font-bold">
                    メールをご確認ください！
                </h1>
                <p className="mt-4 text-left text-base text-gray-800">
                    メールアドレスの確認のため、
                    <br />
                    {email}にメールを送りました。
                    <br />
                    メールをご確認いただき、メール内のリンクをクリックください。
                </p>
            </section>
            <img
                className="mx-auto mt-10"
                src={MailboxImage}
                alt="MailboxImage"
            />
        </div>
    );
};
