import { createContext, Dispatch, SetStateAction, useContext } from "react";
import { Answerer } from "../api/generated";

export type Relationship = "father" | "mother" | "others";
export type Frequency =
    | "Sunday"
    | "Monday"
    | "Tuesday"
    | "Wednesday"
    | "Thursday"
    | "Friday"
    | "Saturday";
export type QuestionSendType = "Email" | "Line";

// FIXME modelとして型定義別の所でしてもいいかも
export type NewAnswererInfo = {
    answererId: string;
    name: string;
    relationship: Relationship;
    birthday: string;
    receiverNickname: string;
    email: string;
    qmailSendFrequency: Frequency[];
    questionSendType: QuestionSendType;
    answerers: Array<Answerer>;
};

export type HamburgerMenuInfo = {
    hamburgerMenuIsOpen: boolean;
    menuAnimation: string;
};

export type AppState = {
    isLoggedIn: boolean;
    cognitoUsername?: string;
    csrfToken: string;
    hamburgerMenuInfo: HamburgerMenuInfo;
    newAnswererInfo: NewAnswererInfo;
    tempLikedMemoryIds: string[];
    goBackMemoryId: string | undefined;
};

export const initialState: AppState = {
    isLoggedIn: false,
    cognitoUsername: undefined,
    hamburgerMenuInfo: {
        hamburgerMenuIsOpen: false,
        menuAnimation: "",
    },
    csrfToken: "",
    newAnswererInfo: {
        answererId: "",
        name: "",
        relationship: "others",
        birthday: "",
        receiverNickname: "",
        email: "",
        qmailSendFrequency: ["Tuesday", "Saturday"],
        questionSendType: "Email",
        answerers: [],
    },
    tempLikedMemoryIds: [],
    goBackMemoryId: undefined,
};

export const AppStateContext = createContext<AppState>(initialState);
export const SetAppStateContext = createContext<
    Dispatch<SetStateAction<AppState>>
>(() => {
    // nop
});

export function useAppState() {
    return useContext(AppStateContext);
}
export function useSetAppState() {
    return useContext(SetAppStateContext);
}
