import { client, DEFAULT_HEADERS } from "./defaultClient";
import { Memory } from "./generated";

export const getMemories = async (
    lastMemoryId: string | undefined,
    limit: number
): Promise<{ memories: Memory[]; hasNextPage: boolean }> => {
    const response = await client.getMemories(
        { lastMemoryId: lastMemoryId, limit: limit },
        { mode: "cors" }
    );
    return { memories: response.memories, hasNextPage: response.hasNextMemory };
};

export const addCommentOnMemory = async (
    memoryId: string,
    text: string,
    imagePaths: Array<string>,
    csrfToken: string
): Promise<Memory> =>
    await client.postMemoryMemoryIdComments(
        { memoryId, newComment: { text, imagePaths } },
        {
            mode: "cors",
            headers: {
                ...DEFAULT_HEADERS,
                "X-CSRF-Token": csrfToken,
            },
        }
    );

export const getMemory = async (memoryId: string): Promise<Memory> =>
    await client.getMemoryMemoryId({ memoryId }, { mode: "cors" });

export const incrementMemoryLikedCount = async (
    memoryId: string,
    csrfToken: string
): Promise<Memory> =>
    await client.patchMemoryMemoryIdLikedCount(
        { memoryId },
        {
            mode: "cors",
            headers: {
                ...DEFAULT_HEADERS,
                "X-CSRF-Token": csrfToken,
            },
        }
    );

export const uploadCommentImage = async (
    file: File,
    csrfToken: string
): Promise<string> => {
    const response = await client.postUsersMeAnswererUploadCommentImage(
        {
            commentImageFile: file,
        },
        {
            mode: "cors",
            headers: {
                "X-CSRF-Token": csrfToken,
            },
        }
    );
    return response.filePath ?? "";
};
