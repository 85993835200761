import { ToggleButton } from "@twioku/common_components";
import { Frequency } from "../../../stores/appContext";
import { daysOfWeek } from "../../../libs/constants";

type Props = {
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    selectedSendFrequency: Frequency[];
    className?: string;
};

export const SendFrequencyForm: React.FC<Props> = (props: Props) => {
    const { handleChange, selectedSendFrequency, className } = props;
    return (
        <div className={`${className}`}>
            {daysOfWeek.map((item, index) => {
                return (
                    <div key={index} className="border-b-[1px]">
                        <div className="mx-4 flex justify-between py-2">
                            <label>毎週{item.japanese}</label>
                            <ToggleButton
                                checked={selectedSendFrequency.includes(
                                    item.english
                                )}
                                value={item.english.toString()}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
