import React, { useEffect, useRef, useState } from "react";

type Props = {
    src: string;
    className?: string;
};

export const VideoPlayer: React.FC<Props> = ({ src, className }: Props) => {
    const [thumbnailSrc, setThumbnailSrc] = useState<string>("");
    const videoRef = useRef<HTMLVideoElement>(null);

    const generateThumbnail = () => {
        if (videoRef.current) {
            const canvas = document.createElement("canvas");
            canvas.width = videoRef.current.videoWidth;
            canvas.height = videoRef.current.videoHeight;
            videoRef.current.currentTime = 0.001;
            canvas.getContext("2d")?.drawImage(videoRef.current, 0, 0);

            const thumbnail = canvas.toDataURL("image/jpeg");
            setThumbnailSrc(thumbnail);
        }
    };

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.addEventListener("loadeddata", generateThumbnail);
            videoRef.current.load();
        }
    }, [src]);

    const cancelParentEvent = (e: React.MouseEvent<HTMLVideoElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <div className={`${className} flex items-center justify-center`}>
            <video
                poster={thumbnailSrc}
                ref={videoRef}
                width={350}
                src={src}
                controls
                crossOrigin="anonymous"
                onClick={cancelParentEvent}
            />
        </div>
    );
};
