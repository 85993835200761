import { useNavigate } from "react-router-dom";
import UseStartMailRemind from "../assets/use_start_mail_remind.svg";
import { Button, SpinAnimation } from "@twioku/common_components";
import { Path } from "../routes/PagePath";
import { useAnswerers } from "../features/answerer/hooks/useAnswerers";

export const UseStartMailRemindPage: React.FC = () => {
    const navigate = useNavigate();
    const { answerers, isLoading } = useAnswerers();

    if (isLoading)
        return (
            <div className="absolute inset-0 flex items-center justify-center">
                <SpinAnimation />
            </div>
        );

    const notAgreedAnswerers = answerers.filter(
        (answerer) => !answerer.isPrivacyAndUsageTermsAgreed
    );
    const answererName =
        notAgreedAnswerers.length !== 0
            ? `${notAgreedAnswerers[0].name}さん${
                  notAgreedAnswerers.length > 1 ? "たち" : ""
              }`
            : "やり取り相手";

    return (
        <>
            <div className="flex flex-col items-center p-8">
                <h1 className="mt-4 text-xl font-bold">
                    {`${
                        answererName && answererName + "に"
                    }ツイオクの利用開始を承諾してもらいましょう！`}
                </h1>
                <p className="mt-6 text-gray-600">
                    ご利用開始案内をご確認・ご了承いただくことで、ツイオクのサービスが開始されます。
                    <br />
                    親御様さまにご確認をお願いいたします。
                    <br />
                    <span className="text-sm text-gray-400">
                        ※ご了承されるまではサービス開始されませんのでご注意ください。
                    </span>
                </p>
                <img
                    src={UseStartMailRemind}
                    className="mt-16 w-2/3 max-w-md"
                />
                <div className="fixed bottom-0 left-1/2 w-11/12 -translate-x-1/2 bg-white">
                    <Button
                        onClick={() => navigate(Path.answerers)}
                        className="mb-8 h-12 w-full"
                    >
                        詳細はこちら
                    </Button>
                </div>
            </div>
        </>
    );
};
