import React, { createRef, useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Frequency, Relationship } from "../stores/appContext";
import { EmailVerificationAlert } from "../features/answerer/components/EmailVerificationAlert";
import { LineVerificationAlert } from "../features/answerer/components/LineVerificationAlert";
import {
    Button,
    showToast,
    Tooltip,
    ImageEditorModal,
} from "@twioku/common_components";
import { ConfirmContentInput } from "../features/newAnswererInfo/components/ConfirmContentInput";
import { ConfirmContentFrequency } from "../features/newAnswererInfo/components/ConfirmContentFrequency";
import { ConfirmContentSelect } from "../features/newAnswererInfo/components/ConfirmContentSelect";
import { sortFrequencyJapanese } from "../libs/helper";
import { relationships } from "../libs/constants";
import { ProfileImage } from "@twioku/common_components";
import { SpinAnimation } from "@twioku/common_components";
import { Path } from "../routes/PagePath";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Answerer } from "../api/generated";
import {
    UpdateAnswererInput,
    updateAnswererInputSchema,
    useAnswerer,
    useSendVerifyEmailToAnswerer,
    useUpdateAnswerer,
    useUploadAnswererProfileImage,
} from "../features/answerer/hooks/useAnswerer";
import { useAnswerersV2 } from "../features/answerer/hooks/useAnswerers";
import { useCreateLineLinkOneTimePassword } from "../features/lineLink/hooks/useLineLink";
import { Modal } from "@mui/material";
import clsx from "clsx";
import { LineLinkShare } from "../components/LineLinkShare";
import { useFeatureEnabled } from "../features/featureFlag/hooks/useFeatureEnabled";

export const AnswererDetailPage: React.FC = () => {
    const id = useParams().id as string;
    const navigate = useNavigate();

    const { answerer, isLoading: isLoadingAnswerer } = useAnswerer(id);
    const { answerers, isLoading: isLoadingAnswerers } = useAnswerersV2();

    if (isLoadingAnswerer || isLoadingAnswerers) {
        return (
            <div className="absolute inset-0 flex items-center justify-center">
                <SpinAnimation />
            </div>
        );
    }

    if (!(answerer && answerers)) {
        navigate(Path.error);
        return null;
    }

    return (
        <AnswererDetailPageContent answerer={answerer} answerers={answerers} />
    );
};

type AnswererDetailPageContentProps = {
    answerer: Answerer;
    answerers: Answerer[];
};

const AnswererDetailPageContent: React.FC<AnswererDetailPageContentProps> = ({
    answerer,
    answerers,
}) => {
    const navigate = useNavigate();

    const qmailSendFrequencyJapanese = useMemo(() => {
        return sortFrequencyJapanese(answerer.qmailFrequency as Frequency[]);
    }, [answerer]);

    const [profileImagePath, setProfileImagePath] = useState<
        string | undefined
    >(answerer.profileImagePath);
    const [isMailSendedSuccess, setIsMailSendedSuccess] = useState(false);

    const { clearCache: clearAnswererCache } = useAnswerer(answerer.answererId);
    const { updateAnswerer } = useUpdateAnswerer(answerer);
    const { createLineLinkOneTimePassword } =
        useCreateLineLinkOneTimePassword(answerer);
    const [openShareLineLinkModal, setOpenShareLineLinkModal] =
        useState<boolean>(false);
    const [lineLinkOneTimePassword, setLineLinkOneTimePassword] =
        useState<string>("");
    const { sendVerifyEmailToAnswerer } =
        useSendVerifyEmailToAnswerer(answerer);
    const {
        uploadAnswererProfileImage: uploadAnswererProfileImageMutation,
        validateAnswererProfileImage,
    } = useUploadAnswererProfileImage(answerer);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedAvatorFile, setSelectedAvatorFile] = useState<File>();

    const {
        control,
        handleSubmit,
        setError,
        reset,
        formState: { isDirty, dirtyFields, isValid },
        clearErrors,
    } = useForm<UpdateAnswererInput>({
        mode: "onChange",
        resolver: zodResolver(updateAnswererInputSchema),
        defaultValues: {
            name: answerer.name,
            relationship: answerer.relationship as Relationship,
            birthday: answerer.birthday.replaceAll("-", ""),
            receiverNickname: answerer.receiverNickname,
            email: answerer.email,
            questionSendType: answerer.questionSendType as "Email" | "Line",
            qmailFrequency: answerer.qmailFrequency as Frequency[],
        },
    });

    const enabledLineFeature = useFeatureEnabled("line");

    const handleClickSaveChangeButton: SubmitHandler<
        UpdateAnswererInput
    > = async (input) => {
        if (
            answerers.some(
                (other) =>
                    answerer.answererId !== other.answererId &&
                    other.email === input.email
            )
        ) {
            setError("email", {
                message:
                    "このメールアドレスは、既に別の相手へのメールアドレスとして登録されています。別のメールアドレスのご利用をご検討ください。",
            });
            return;
        }

        await updateAnswerer.mutateAsync(input, {
            onSuccess: async () => {
                reset(input);
                showToast("変更の保存が完了しました。", "success");
                await clearAnswererCache();
            },
            onError: () => {
                showToast(
                    "エラーが発生しました。\n管理者にお問合せください。",
                    "error"
                );
            },
        });

        if (
            dirtyFields.questionSendType &&
            input.questionSendType == "Line" &&
            !answerer.lineLinked
        ) {
            setOpenShareLineLinkModal(true);
            createLineLinkOneTimePassword.mutate(undefined, {
                onSuccess: (lineLinkOneTimePassword) => {
                    setLineLinkOneTimePassword(lineLinkOneTimePassword);
                },
                onError: () => {
                    setOpenShareLineLinkModal(false);
                    showToast(
                        "エラーが発生しました。\n管理者にお問合せください。",
                        "error"
                    );
                },
            });
        }
    };

    const handleCloseShareLineLinkModal = useCallback(() => {
        setOpenShareLineLinkModal(false);
    }, []);

    const handleSaveAnswererIcon = async (file: File) => {
        uploadAnswererProfileImageMutation.mutate(file, {
            onSuccess: async (fileImagePath) => {
                setProfileImagePath(fileImagePath);
                showToast("変更内容を保存しました。");
                await clearAnswererCache();
            },
            onError: () => {
                showToast("予期しないエラーが発生しました。", "error");
            },
        });
        setIsOpen(false);
    };

    const handleClickAnswererIcon = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const fileList = event.target.files;
        if (!fileList) return;
        const file = fileList[0];

        const validatedResult = validateAnswererProfileImage(file);
        if (!validatedResult.isSuccess) {
            showToast(validatedResult.message, "error");
            return;
        }

        setSelectedAvatorFile(file);
        setIsOpen(true);
        // 同じ画像を再選択したときもChangeEventが発火するようにしています。
        event.target.value = "";
    };

    const inputRef = createRef<HTMLInputElement>();
    const handleProfileChangeButtonClick = () => inputRef.current?.click();

    const handleResendMailButtonClick = async () => {
        if (
            window.confirm(
                "メールアドレス確認と利用同意のメールを再送信します。よろしいですか？"
            )
        ) {
            sendVerifyEmailToAnswerer.mutate(undefined, {
                onSuccess: () => {
                    setIsMailSendedSuccess(true);
                    showToast("疎通確認メールを送信しました。", "success");
                },
                onError: () => {
                    showToast(
                        "疎通確認メール送信に失敗しました。\n宛先メールアドレスを再度確認してください。",
                        "error"
                    );
                },
            });
        }
    };

    const questionSendTypeOptions: Array<{
        value: "Email" | "Line";
        label: string;
    }> = useMemo(() => {
        const options: Array<{ value: "Email" | "Line"; label: string }> = [
            { label: "メール", value: "Email" },
        ];
        if (enabledLineFeature) {
            options.push({ label: "LINE", value: "Line" });
        }
        return options;
    }, [enabledLineFeature]);

    const renderVerificationAlert = () => {
        if (answerer.isPrivacyAndUsageTermsAgreed) return null;

        if (answerer.questionSendType === "Email") {
            if (isMailSendedSuccess) return null;
            return (
                <EmailVerificationAlert
                    handleClickResendMailButton={handleResendMailButtonClick}
                    isLoading={sendVerifyEmailToAnswerer.isLoading}
                />
            );
        }

        if (answerer.questionSendType === "Line") {
            if (answerer.lineLinked) return null;
            return <LineVerificationAlert answerer={answerer} />;
        }

        return null;
    };

    return (
        <>
            <Modal
                classes={{
                    root: "flex items-center justify-center",
                }}
                open={openShareLineLinkModal}
                onClose={handleCloseShareLineLinkModal}
            >
                <div className="flex max-w-[95vw] flex-col items-center gap-4 rounded border bg-white p-4 outline-none">
                    {createLineLinkOneTimePassword.isLoading ? (
                        <SpinAnimation />
                    ) : (
                        <>
                            <LineLinkShare
                                lineLinkOneTimePassword={
                                    lineLinkOneTimePassword
                                }
                            />
                        </>
                    )}
                </div>
            </Modal>
            <div className="pb-32">
                <input
                    ref={inputRef}
                    type="file"
                    className="absolute hidden"
                    accept="image/jpeg,image/png,image/gif"
                    onChange={(e) => handleClickAnswererIcon(e)}
                />
                <div className="flex flex-col items-center justify-center">
                    <div
                        className="mb-2 mt-5"
                        onClick={handleProfileChangeButtonClick}
                    >
                        <ProfileImage src={profileImagePath} size="large" />
                    </div>
                </div>

                <ImageEditorModal
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    file={selectedAvatorFile}
                    savedEditedFile={handleSaveAnswererIcon}
                />

                {renderVerificationAlert()}

                <div className="mt-2 w-full flex-1 overflow-y-auto break-words border-t-[3px] border-gray-200 pt-1">
                    <Controller
                        name="name"
                        control={control}
                        render={({ field, fieldState }) => (
                            <ConfirmContentInput
                                label="お名前"
                                value={field.value}
                                name={field.name}
                                handleChangeText={field.onChange}
                                errorMessage={fieldState.error?.message ?? ""}
                            />
                        )}
                    />
                    <Controller
                        name="relationship"
                        control={control}
                        render={({ field }) => (
                            <ConfirmContentSelect<Relationship>
                                label="あなたとの関係性"
                                value={field.value}
                                name={field.name}
                                options={relationships}
                                handleSelect={field.onChange}
                            />
                        )}
                    />
                    <Controller
                        name="birthday"
                        control={control}
                        render={({ field, fieldState }) => (
                            <ConfirmContentInput
                                label="お誕生日"
                                value={field.value}
                                name={field.name}
                                handleChangeText={field.onChange}
                                errorMessage={fieldState.error?.message ?? ""}
                            />
                        )}
                    />
                    <Controller
                        name="receiverNickname"
                        control={control}
                        render={({ field, fieldState }) => (
                            <ConfirmContentInput
                                label="あなたを呼ぶニックネーム"
                                value={field.value}
                                name={field.name}
                                handleChangeText={field.onChange}
                                errorMessage={fieldState.error?.message ?? ""}
                            />
                        )}
                    />
                    <Controller
                        name="email"
                        control={control}
                        render={({ field, fieldState }) => (
                            <ConfirmContentInput
                                label="メールアドレス"
                                value={field.value}
                                name={field.name}
                                handleChangeText={field.onChange}
                                errorMessage={fieldState.error?.message ?? ""}
                            />
                        )}
                    />
                    <Controller
                        name="questionSendType"
                        control={control}
                        render={({ field }) => (
                            <ConfirmContentSelect<"Email" | "Line">
                                label="やり取りの方法"
                                rightContent={
                                    field.value === "Line" && (
                                        <div className="flex items-center gap-1">
                                            <div
                                                className={clsx(
                                                    "whitespace-nowrap rounded-full px-4 py-1",
                                                    {
                                                        "bg-green-100 text-green-700":
                                                            answerer.lineLinked,
                                                        "bg-orange-100 text-orange-700":
                                                            !answerer.lineLinked,
                                                    }
                                                )}
                                            >
                                                <div>
                                                    {answerer.lineLinked
                                                        ? "連携済"
                                                        : "未連携"}
                                                </div>
                                            </div>
                                            {answerer.lineProfile && (
                                                <div className="h-8 w-8">
                                                    <Tooltip
                                                        position="top-end"
                                                        text={
                                                            answerer.lineProfile
                                                                .displayName
                                                        }
                                                    >
                                                        <ProfileImage
                                                            className="h-full w-full border border-gray-300"
                                                            size="small"
                                                            src={
                                                                answerer
                                                                    .lineProfile
                                                                    .pictureUrl
                                                            }
                                                            alt={
                                                                answerer
                                                                    .lineProfile
                                                                    .displayName
                                                            }
                                                        />
                                                    </Tooltip>
                                                </div>
                                            )}
                                        </div>
                                    )
                                }
                                value={field.value}
                                name={field.name}
                                options={questionSendTypeOptions}
                                handleSelect={(option) => {
                                    clearErrors("email");
                                    field.onChange(option);
                                }}
                            />
                        )}
                    />
                    <ConfirmContentFrequency
                        label="問いかけメールの送信頻度"
                        value={qmailSendFrequencyJapanese.join("、")}
                        handleClickFrequencyEditIcon={() =>
                            navigate(
                                `/answerer/${answerer.answererId}/qmailsendfrequency`
                            )
                        }
                        errorMessage=""
                    />
                </div>
                <div className="fixed bottom-0 left-1/2 w-full -translate-x-1/2 bg-white text-center">
                    <Button
                        onClick={handleSubmit(handleClickSaveChangeButton)}
                        className="mb-8 mt-2 h-12 w-11/12"
                        disabled={!isDirty || !isValid}
                        loading={updateAnswerer.isLoading}
                    >
                        変更を保存する
                    </Button>
                </div>
            </div>
        </>
    );
};
