import { client } from "./defaultClient";

export const signin = async (email: string, password: string) => {
    return await client.postAuthSignin(
        { postAuthSigninRequest: { email, password } },
        { mode: "cors" }
    );
};

export const signout = async (csrfToken: string) => {
    return await client.postAuthSignout({
        mode: "cors",
        headers: { "X-CSRF-Token": csrfToken },
    });
};

export const getCSRFToken = async (): Promise<string> => {
    const data = await client.getCsrfToken({ mode: "cors" });
    return data.csrfToken;
};

export const signup = async (email: string, password: string) => {
    return await client.postAuthSignup(
        { postAuthSignupRequest: { email, password } },
        { mode: "cors" }
    );
};

export const verifyAuthToken = async () =>
    client.getAuthVerify({ mode: "cors" });

export const sendPasswordResetMail = async (email: string): Promise<void> => {
    await client.postAuthSendPasswordResetEmail(
        { postAuthSendPasswordResetEmailRequest: { email: email } },
        { mode: "cors" }
    );
};

export const cognitoUserPasswordReset = async (
    userName: string,
    confirmationCode: string,
    newPassword: string
) => {
    await client.postAuthPasswordReset(
        {
            postAuthPasswordResetRequest: {
                userName: userName,
                confirmationCode: confirmationCode,
                newPassword: newPassword,
            },
        },
        { mode: "cors" }
    );
};
