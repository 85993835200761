import React, { ReactNode } from "react";
import { Helmet } from "react-helmet-async";
import { Header } from "./Header";
import { pageTitles } from "../routes/PageTitle";
import { useLocation, matchPath } from "react-router-dom";
import { useHamburgerMenuInfo } from "../features/hamburgerMenu/useHamburgerMenuInfo";
import { HamburgerMenu } from "./HamburgerMenu";
import { Path } from "../routes/PagePath";

type Props = {
    children: ReactNode;
};

export const Layout: React.FC<Props> = ({ children }) => {
    const { pathname } = useLocation();
    const { hamburgerMenuInfo } = useHamburgerMenuInfo();

    const currentPageTitle = pageTitles.find((title) =>
        matchPath(title.path, pathname)
    );

    const hiddenScrollbarPageList = [Path.memorydetail];
    const isHiddenScrollbar = hiddenScrollbarPageList.some((path) =>
        matchPath(path, pathname)
    );

    return (
        <div className="mx-auto flex h-full max-w-2xl flex-col">
            <Helmet>
                <title>
                    {currentPageTitle
                        ? `${currentPageTitle?.title} | ツイオク`
                        : "ツイオク"}
                </title>
            </Helmet>
            <Header />
            <main
                className={`relative h-full flex-1 overflow-y-auto pt-16 ${
                    isHiddenScrollbar ? "hidden-scrollbar" : ""
                }`}
            >
                <div className={`${hamburgerMenuInfo.menuAnimation}`}>
                    <div
                        className={
                            hamburgerMenuInfo.hamburgerMenuIsOpen
                                ? "hidden"
                                : ""
                        }
                    >
                        {children}
                    </div>
                    <div
                        className={
                            hamburgerMenuInfo.hamburgerMenuIsOpen
                                ? ""
                                : "hidden"
                        }
                    >
                        <HamburgerMenu />
                    </div>
                </div>
            </main>
            <div id="image-viewer"></div>
        </div>
    );
};
