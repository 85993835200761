import React from "react";
import { Button } from "@twioku/common_components";

type Props = {
    handleClickResendMailButton: () => Promise<void>;
    isLoading: boolean;
};

export const EmailVerificationAlert: React.FC<Props> = (props: Props) => {
    const { handleClickResendMailButton, isLoading } = props;
    return (
        <>
            <div className="flex justify-center py-3">
                <div className="mx-2 my-auto inline-block h-8 w-8 rounded-full bg-yellow-100 p-2">
                    <svg
                        className="h-4 w-4 fill-current text-yellow-500"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                    >
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z" />
                    </svg>
                </div>
                <div className="mr-5">
                    <p className="text-sm text-gray-600">
                        <span className="font-bold">
                            まだご利用案内のメールにご返信いただけていないようです。
                            <br />
                            ご利用案内のメールにご返信いただけない場合、問いかけメールの送信は開始されません。
                        </span>
                        <br />
                        お相手の方にご返信いただきますようお願いします。
                        <br />
                        以下のボタンから、再度ご利用案内のメールをお送りすることもできます。
                    </p>
                </div>
            </div>
            <div className="w-full text-center">
                <Button
                    type="button"
                    onClick={handleClickResendMailButton}
                    loading={isLoading}
                    className="mx-auto h-auto w-auto p-2"
                >
                    {!isLoading
                        ? "もう一度利用開始案内のメールを送る"
                        : "送信中"}
                </Button>
            </div>
        </>
    );
};
