import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { changeEmailConfirm } from "../api/user";
import { showToast } from "@twioku/common_components";
import { Path } from "../routes/PagePath";

export const EmailConfirmPage: React.FC = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");
    const navigate = useNavigate();

    const [rendered, setRendered] = useState<boolean>(false);

    useEffect(() => {
        // 2 重実行防止
        if (!rendered) {
            setRendered(true);
            return;
        }

        if (code == null) {
            navigate(Path.profile);
            return;
        }

        changeEmailConfirm(code)
            .then(() => {
                showToast("メールアドレスが確認できました。");
            })
            .catch((error) => {
                console.error(error);
                showToast(
                    "メールアドレスの確認に失敗しました。再度お試しください。",
                    "error"
                );
            })
            .finally(() => {
                navigate(Path.profile);
            });
    }, [code, navigate, rendered]);

    return null;
};
