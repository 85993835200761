import { client, DEFAULT_HEADERS } from "./defaultClient";
import { User } from "./generated";

export const getMe = async (): Promise<User> => {
    return await client.getUser({ mode: "cors" });
};

export const uploadMyProfileImage = async (
    file: File,
    csrfToken: string
): Promise<string> => {
    const response = await client.postUsersMeUploadProfileImage(
        {
            meProfileImage: file,
        },
        {
            mode: "cors",
            headers: {
                "X-CSRF-Token": csrfToken,
            },
        }
    );
    return response.filePath ?? "";
};

export const changeEmail = async (
    email: string,
    csrfToken: string
): Promise<void> => {
    await client.postUsersMeChangeEmail(
        {
            postAuthSendPasswordResetEmailRequest: { email },
        },
        {
            mode: "cors",
            headers: {
                ...DEFAULT_HEADERS,
                "X-CSRF-Token": csrfToken,
            },
        }
    );
};

export const changeEmailConfirm = async (code: string) => {
    await client.getUsersMeChangeEmailConfirm(
        {
            code,
        },
        {
            mode: "cors",
            ...DEFAULT_HEADERS,
        }
    );
};
