import { useEffect, useState } from "react";
import GTM from "react-gtm-module";
import { useAppState } from "../../stores/appContext";

export const useGTM = () => {
    const GTM_ID: string | undefined = process.env.VITE_GTM_ID;
    const [gtmInitialized, setGtmInitialized] = useState(false);
    const state = useAppState();
    const { cognitoUsername } = state;

    useEffect(() => {
        if (GTM_ID && !gtmInitialized) {
            GTM.initialize({ gtmId: GTM_ID });
            console.log("GTM initialized");
            setGtmInitialized(true);
        }
    }, [GTM_ID, gtmInitialized]);

    useEffect(() => {
        if (gtmInitialized && cognitoUsername) {
            GTM.dataLayer({
                dataLayer: {
                    user_id: cognitoUsername,
                },
            });
            console.log("GTM dataLayer updated");
        }
    }, [gtmInitialized, cognitoUsername]);
};
