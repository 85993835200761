import React, { useCallback, useMemo } from "react";
import copy from "copy-to-clipboard";
import ClipboardIcon from "../assets/clipboard_icon.svg";
import LineIcon from "../assets/line_icon.png";
import { Button, showToast } from "@twioku/common_components";

type Props = {
    lineLinkOneTimePassword: string;
};

export const LineLinkShare: React.FC<Props> = ({
    lineLinkOneTimePassword,
}: Props) => {
    const lineLinkUrl = useMemo(() => {
        const url = new URL(
            `${import.meta.env.VITE_LIFF_URL}/accountLink/confirm`
        );
        const params = new URLSearchParams();
        params.set("oneTimePassword", lineLinkOneTimePassword);
        url.search = params.toString();
        return url.toString();
    }, [lineLinkOneTimePassword]);

    const handleCopyLineLinkUrl = useCallback(() => {
        if (copy(lineLinkUrl)) {
            showToast("リンクをコピーしました。");
        }
    }, [lineLinkUrl]);

    return (
        <>
            <p className="text-sm text-gray-500">
                以下のURLを親御さまのLINEに共有してください
            </p>
            <div className="flex max-w-full gap-2">
                <div className="flex-grow overflow-x-auto whitespace-nowrap rounded bg-gray-200 px-2 py-1">
                    {lineLinkUrl}
                </div>
                <button
                    className="block"
                    style={{ minWidth: 24 }}
                    onClick={handleCopyLineLinkUrl}
                >
                    <img src={ClipboardIcon} alt="" />
                </button>
            </div>
            <Button
                className="flex h-12 w-full items-center justify-center gap-2"
                onClick={() => {
                    const url = new URL(
                        "https://social-plugins.line.me/lineit/share"
                    );
                    const params = new URLSearchParams();
                    params.set("url", lineLinkUrl);
                    url.search = params.toString();
                    window.open(url.toString());
                }}
            >
                <img src={LineIcon} alt="" width={24} height={24} />
                LINEで共有する
            </Button>
        </>
    );
};
