import { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { Button } from "@twioku/common_components";
import { useNavigate } from "react-router-dom";
import { Path } from "../../../routes/PagePath";
import { TextInput } from "@twioku/common_components";
import { TextLabel } from "../../../components/TextLabel";
import { useTranslation } from "react-i18next";
import { showToast } from "@twioku/common_components";
import { Link, useLocation } from "react-router-dom";
import { HOMEPAGE_CONTACT_URL } from "../../../constants/Urls";
import { PasswordInput } from "../../../components/PasswordInput";
import { useFeatureEnabled } from "../../featureFlag/hooks/useFeatureEnabled";

type Props = {
    className?: string;
};

// FIXME: react-hook-form を使う
export const SigninForm = (props: Props) => {
    const { className } = props;
    const [rendered, setRendered] = useState<boolean>(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const { signinUser, isLoading } = useAuth();
    const { t } = useTranslation();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const emailVerified = searchParams.get("emailVerified") === "true";
    const redirectTo = searchParams.get("redirectTo");
    const enabledSignUp = useFeatureEnabled("signup");

    useEffect(() => {
        // 2 重実行防止
        if (!rendered) {
            setRendered(true);
            return;
        }

        if (emailVerified) {
            showToast("メールアドレスが確認できました。");
        }
    }, [rendered, emailVerified]);

    const handleSignin = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        signin();
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        signin();
    };

    const signin = () => {
        signinUser(email, password, () => {
            if (redirectTo && redirectTo.startsWith("/")) {
                navigate(redirectTo);
            } else {
                navigate(Path.root);
            }
        });
    };

    return (
        <form
            className={className}
            onSubmit={handleSubmit}
            action="#"
            method="POST"
        >
            <div>
                <TextLabel htmlFor="signin-email">
                    {t("feature.auth.signin.email")}
                </TextLabel>
                <TextInput
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    name="signin-email"
                    className="w-full"
                    value={email}
                    placeholder="ex) ~~~@example.com"
                />
            </div>
            <div className="mb-1 mt-4">
                <TextLabel htmlFor="signin-password">
                    {t("feature.auth.signin.password")}
                </TextLabel>
                <p className="my-1 pl-1 text-xs text-gray-500">
                    アルファベットの大文字、小文字、数字、全て含んだ8文字以上
                </p>
                <PasswordInput
                    handleChange={(e) => setPassword(e.target.value)}
                    name="signin-password"
                    value={password}
                    placeholder="パスワード"
                />
            </div>
            {enabledSignUp && (
                <p className="mt-6 text-right text-sm">
                    <Link to={Path.signup} className="text-primary">
                        アカウントをお持ちでない場合は
                        {t("page.signup.header")}
                    </Link>
                </p>
            )}
            <p className="text-right text-sm">
                <Link
                    to={Path.passwordResetSendConfirmationCode}
                    className="text-primary"
                >
                    パスワードを忘れた場合
                </Link>
            </p>
            <p className="py-2 text-right text-sm">
                <a
                    href={HOMEPAGE_CONTACT_URL}
                    className="text-primary"
                    target={"_blank"}
                    rel={"noopener noreferrer"}
                >
                    お問い合わせはこちら &gt;&gt;
                </a>
            </p>
            <div className="fixed bottom-0 left-1/2 w-11/12 -translate-x-1/2 bg-white">
                <p className="py-2 text-left text-xs text-gray-500">
                    ※プライベートブラウズ（シークレットモード）ではログイン情報が保存されず、毎回ログインが必要になる場合があります。通常のブラウズモードでのご利用をおすすめします。
                </p>
                <Button
                    onClick={handleSignin}
                    className="mb-8 mt-2 h-12 w-full"
                    type="submit"
                    disabled={isLoading || !email || !password}
                    loading={isLoading}
                >
                    {t("feature.auth.signin.submit")}
                </Button>
            </div>
        </form>
    );
};
