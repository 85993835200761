import { useCallback, useEffect, useMemo, useState } from "react";
import { Answerer } from "../../../api/generated";
import { getAnswerers } from "../../../api/answerer";
import { Path } from "../../../routes/PagePath";
import { initialState, useSetAppState } from "../../../stores/appContext";
import { useQuery, useQueryClient } from "@tanstack/react-query";

export const useAnswerersV2 = () => {
    const queryKey = useMemo(() => ["answerers"], []);
    const queryClient = useQueryClient();

    const { data: answerers, isLoading } = useQuery(queryKey, () =>
        getAnswerers()
    );

    const clearCache = useCallback(async () => {
        await queryClient.invalidateQueries(queryKey);
    }, [queryClient, queryKey]);

    return {
        answerers,
        isLoading,
        clearCache,
    };
};

/**
 * FIXME: 使用している箇所を useAnswerersV2 に置き換えて、この関数を削除する
 * @deprecated
 */
export const useAnswerers = () => {
    const [answerers, setAnswerers] = useState<Answerer[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const setAppState = useSetAppState();

    useEffect(() => {
        setIsLoading(true);
        getAnswerers()
            .then((answerers) => {
                setAnswerers(answerers);
                const initNewAnswererInfo = {
                    // FIXME: answerersはgetしたタイミングでinitNewAnswererInfoとは独立してglobalstateに持ってもいいかな〜と思いました。（あんまり関連させておく意味がなさそうなので、読む時に混乱したし）
                    ...initialState.newAnswererInfo,
                    answerers: answerers,
                };
                setAppState((state) => ({
                    ...state,
                    newAnswererInfo: initNewAnswererInfo,
                }));
            })
            .catch(() => {
                window.location.href = Path.error;
            })
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        answerers,
        isLoading,
    };
};
