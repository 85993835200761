/* tslint:disable */
/* eslint-disable */
/**
 * Twioku-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetMemoryMemoryIdResponseStatus200Response
 */
export interface GetMemoryMemoryIdResponseStatus200Response {
    /**
     * 
     * @type {string}
     * @memberof GetMemoryMemoryIdResponseStatus200Response
     */
    responseStatus: GetMemoryMemoryIdResponseStatus200ResponseResponseStatusEnum;
}


/**
 * @export
 */
export const GetMemoryMemoryIdResponseStatus200ResponseResponseStatusEnum = {
    NotAnsweredYet: 'NOT_ANSWERED_YET',
    NoAnswer: 'NO_ANSWER',
    Answered: 'ANSWERED'
} as const;
export type GetMemoryMemoryIdResponseStatus200ResponseResponseStatusEnum = typeof GetMemoryMemoryIdResponseStatus200ResponseResponseStatusEnum[keyof typeof GetMemoryMemoryIdResponseStatus200ResponseResponseStatusEnum];


/**
 * Check if a given object implements the GetMemoryMemoryIdResponseStatus200Response interface.
 */
export function instanceOfGetMemoryMemoryIdResponseStatus200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "responseStatus" in value;

    return isInstance;
}

export function GetMemoryMemoryIdResponseStatus200ResponseFromJSON(json: any): GetMemoryMemoryIdResponseStatus200Response {
    return GetMemoryMemoryIdResponseStatus200ResponseFromJSONTyped(json, false);
}

export function GetMemoryMemoryIdResponseStatus200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMemoryMemoryIdResponseStatus200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'responseStatus': json['responseStatus'],
    };
}

export function GetMemoryMemoryIdResponseStatus200ResponseToJSON(value?: GetMemoryMemoryIdResponseStatus200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'responseStatus': value.responseStatus,
    };
}

