import { useMutation } from "@tanstack/react-query";
import { useAuth } from "../../auth/hooks/useAuth";
import { createLineLinkOneTimePassword } from "../../../api/line";
import { Answerer } from "../../../api/generated";

export const useCreateLineLinkOneTimePassword = (answerer: Answerer) => {
    const { csrfToken } = useAuth();

    const mutation = useMutation({
        mutationFn: async (): Promise<string> => {
            const oneTimePassword = await createLineLinkOneTimePassword(
                answerer.answererId,
                csrfToken
            );
            return oneTimePassword;
        },
    });

    return {
        createLineLinkOneTimePassword: mutation,
    };
};
