import { DEFAULT_HEADERS, client } from "./defaultClient";

export const updateLastAccessedDatetime = (csrfToken: string) => {
    if (csrfToken === "") return;
    client.postUsersMeLastAccessedDatetime({
        mode: "cors",
        headers: {
            ...DEFAULT_HEADERS,
            "X-CSRF-Token": csrfToken,
        },
    });
};
