import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    UpdateAnswererInput,
    updateAnswererInputSchema,
    useAnswerer,
    useUpdateAnswerer,
} from "../features/answerer/hooks/useAnswerer";
import { Frequency, Relationship } from "../stores/appContext";
import { Button, SpinAnimation, showToast } from "@twioku/common_components";
import { SendFrequencyForm } from "../features/newAnswererInfo/components/SendFrequencyForm";
import { ProfileImage } from "@twioku/common_components";
import { Path } from "../routes/PagePath";
import { Answerer } from "../api/generated";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

export const AnswererDetailQmailSendFrequencyPage: React.FC = () => {
    const id = useParams().id as string;
    const navigate = useNavigate();

    const { answerer, isLoading } = useAnswerer(id);

    if (isLoading) {
        return (
            <div className="absolute inset-0 flex items-center justify-center">
                <SpinAnimation />
            </div>
        );
    }

    if (!answerer) {
        navigate(Path.error);
        return null;
    }

    return <AnswererDetailQmailSendFrequencyPageContent answerer={answerer} />;
};

type AnswererDetailQmailSendFrequencyPageContentProps = {
    answerer: Answerer;
};

const AnswererDetailQmailSendFrequencyPageContent: React.FC<
    AnswererDetailQmailSendFrequencyPageContentProps
> = ({ answerer }) => {
    const id = useParams().id as string;

    const { clearCache: clearAnswererCache } = useAnswerer(id);
    const { updateAnswerer } = useUpdateAnswerer(answerer);

    const {
        control,
        handleSubmit,
        reset,
        getValues,
        setValue,
        formState: { isDirty, isValid },
    } = useForm<UpdateAnswererInput>({
        mode: "onChange",
        resolver: zodResolver(updateAnswererInputSchema),
        defaultValues: {
            name: answerer.name,
            relationship: answerer.relationship as Relationship,
            birthday: answerer.birthday.replaceAll("-", ""),
            receiverNickname: answerer.receiverNickname,
            email: answerer.email,
            qmailFrequency: answerer.qmailFrequency as Frequency[],
            questionSendType: answerer.questionSendType as "Email" | "Line",
        },
    });

    const handleChangeQmailFrequency = useCallback(
        (frequency: Frequency) => {
            const prev = getValues("qmailFrequency");
            const next = prev.includes(frequency)
                ? prev.filter((x) => x !== frequency)
                : [...prev, frequency];
            setValue("qmailFrequency", next, {
                shouldValidate: true,
                shouldDirty:
                    JSON.stringify(prev.sort()) !== JSON.stringify(next.sort()),
            });
        },
        [getValues, setValue]
    );

    const handleClickSaveChangeButton: SubmitHandler<
        UpdateAnswererInput
    > = async (input) => {
        updateAnswerer.mutate(input, {
            onSuccess: async () => {
                reset(input);
                showToast("変更の保存が完了しました。", "success");
                await clearAnswererCache();
            },
            onError: () => {
                showToast(
                    "エラーが発生しました。\n管理者にお問合せください。",
                    "error"
                );
            },
        });
    };

    return (
        <>
            <div className="flex flex-col items-center justify-center">
                <div className="mb-2 mt-5">
                    <ProfileImage
                        src={answerer.profileImagePath}
                        size="large"
                    />
                </div>
                <button className="text-sm text-gray-600">
                    相手：{answerer.name}
                </button>
            </div>

            <span className="ml-2 mt-4 block text-xs text-gray-400">
                問いかけメールの送信頻度
            </span>
            <Controller
                name="qmailFrequency"
                control={control}
                render={({ field, fieldState }) => (
                    <>
                        <SendFrequencyForm
                            handleChange={(e) => {
                                const value = e.currentTarget
                                    .value as Frequency;
                                handleChangeQmailFrequency(value);
                            }}
                            selectedSendFrequency={field.value}
                        />
                        <span className="block text-center text-base text-red-500">
                            {fieldState.error?.message}
                        </span>
                    </>
                )}
            />

            <span className="mx-5 my-3 block text-left text-xs text-gray-400">
                ※変更を保存しますと、送信頻度が変更になった旨を通知するメールが、お相手の方に送信されます。
            </span>
            <div className="fixed bottom-0 left-1/2 w-full -translate-x-1/2 bg-white text-center">
                <Button
                    onClick={handleSubmit(handleClickSaveChangeButton)}
                    className="mb-8 mt-2 h-12 w-11/12"
                    disabled={!isDirty || !isValid}
                    loading={updateAnswerer.isLoading}
                >
                    変更を保存する
                </Button>
            </div>
        </>
    );
};
