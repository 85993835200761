import { TextInput, Button } from "@twioku/common_components";
import { TextLabel } from "../components/TextLabel";
import { useState } from "react";
import { useAuth } from "../features/auth/hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { Path } from "../routes/PagePath";

// FIXME: react-hook-form を使う
export const SendConfirmationCodePage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>("");

    const { isLoading, fetchSendPasswordResetMail } = useAuth();

    const handleSendMail = async (): Promise<void> => {
        fetchSendPasswordResetMail(email, () =>
            navigate(Path.passwordResetSendConfirmationCodeCompleted)
        );
    };

    return (
        <div className="mx-6">
            <h1 className="mb-3 mt-16 text-center text-lg">パスワード再設定</h1>
            <div className="mb-16 flex justify-center px-3">
                ログインに必要な情報をお忘れの方は
                アカウント登録しているメールアドレスをご記入の上、
                「送信」ボタンをクリックしてください。
                ご登録頂いたメールアドレスに
                「パスワード再設定用のリンク」を記載したメールをお送り致します。
            </div>
            <div>
                <TextLabel>メールアドレス</TextLabel>
                <TextInput
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    value={email}
                    placeholder="ex) ~~~@example.com"
                    className="w-full"
                />
            </div>
            <div className="fixed bottom-0 left-1/2 w-11/12 -translate-x-1/2 bg-white">
                <Button
                    onClick={handleSendMail}
                    type="button"
                    disabled={isLoading || !email}
                    loading={isLoading}
                    className="mb-8 mt-2 h-12 w-full"
                >
                    送信
                </Button>
            </div>
        </div>
    );
};
