import { useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { TextInput, Button } from "@twioku/common_components";
import { TextLabel } from "../../../components/TextLabel";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Path } from "../../../routes/PagePath";
import { Link } from "react-router-dom";
import { Checkbox } from "@twioku/common_components";
import { HOMEPAGE_CONTACT_URL } from "../../../constants/Urls";
import { PasswordInput } from "../../../components/PasswordInput";

type Props = {
    className?: string;
};

// FIXME: react-hook-form を使う
export const SignupForm = (props: Props) => {
    const { className } = props;
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isAgreed, setIsAgreed] = useState<boolean>(false);
    const { signupUser, isLoading } = useAuth();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleSignup = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        signup();
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        signup();
    };

    const handleToggleAgreed = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsAgreed(e.target.checked);
    };

    const signup = () => {
        signupUser(email, password, () => {
            const encodedEmail = encodeURIComponent(email);
            navigate(`${Path.signupResult}?email=${encodedEmail}`);
        });
    };

    return (
        <form
            className={className}
            onSubmit={handleSubmit}
            action="#"
            method="POST"
        >
            <div>
                <TextLabel htmlFor="signup-email">
                    {t("feature.auth.signup.email")}
                </TextLabel>
                <TextInput
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    name="signup-email"
                    value={email}
                    placeholder="ex) ~~~@example.com"
                    className="w-full"
                />
            </div>
            <div className="mb-1 mt-4">
                <TextLabel htmlFor="signup-password">
                    {t("feature.auth.signup.password")}
                </TextLabel>
                <p className="my-1 pl-1 text-xs text-gray-500">
                    アルファベットの大文字、小文字、数字、全て含んだ8文字以上
                </p>
                <PasswordInput
                    handleChange={(e) => setPassword(e.target.value)}
                    name="signup-password"
                    value={password}
                    placeholder="パスワード"
                />
            </div>
            <div className="my-6 text-xs">
                <Checkbox
                    checked={isAgreed}
                    onChange={handleToggleAgreed}
                    label={
                        <>
                            <div className="mt-1">
                                本サービスの利用にあたっては、
                                <Link
                                    className="text-primary"
                                    to={Path.terms}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    利用規約
                                </Link>
                                が適用されるものとします。
                            </div>
                        </>
                    }
                />
            </div>
            <p className="mt-6 text-right text-sm">
                <Link to={Path.signin} className="text-primary">
                    アカウントをお持ちの場合は
                    {t("page.signin.header")}
                </Link>
            </p>
            <p className="py-2 text-right text-sm">
                <a
                    href={HOMEPAGE_CONTACT_URL}
                    className="text-primary"
                    target={`_blank`}
                    rel={`noopener noreferrer`}
                >
                    お問い合わせはこちら &gt;&gt;
                </a>
            </p>
            <div className="fixed bottom-0 left-1/2 w-11/12 -translate-x-1/2 bg-white">
                <Button
                    onClick={handleSignup}
                    className="mb-8 mt-2 h-12 w-full"
                    type="submit"
                    disabled={isLoading || !isAgreed || !email || !password}
                    loading={isLoading}
                >
                    {t("feature.auth.signup.submit")}
                </Button>
            </div>
        </form>
    );
};
