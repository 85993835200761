import { useEffect, useState } from "react";
import {
    createAnswerer,
    sendVerifyEmailToAnswerer,
} from "../../../api/answerer";
import { useAuth } from "../../auth/hooks/useAuth";
import {
    NewAnswererInfo,
    useAppState,
    useSetAppState,
    Relationship,
    Frequency,
    QuestionSendType,
} from "../../../stores/appContext";
import { isDate, parseToDate, toFormatString } from "../../../libs/helper";
import { useLocation } from "react-router";
import { Path } from "../../../routes/PagePath";
import { Answerer } from "../../../api/generated/models/Answerer";
import { EMAIL_REGEX } from "../../../libs/constants";
import { showToast } from "@twioku/common_components";

type ValidatedResult = {
    isError: boolean;
    errorMessage: string;
};

type ValidationItems = {
    name: string;
    birthDay: string;
    receiverNickName: string;
    email: string;
    qmailSendFrequency: Frequency[];
    questionSendType: QuestionSendType;
    answerers: Array<Answerer>;
};

// FIXME: 用途ごとに hook を分割し、且つ tanstack query を使う
export const useNewAnswererInfo = () => {
    const state = useAppState();
    const { csrfToken } = useAuth();
    const { newAnswererInfo } = state;
    const setAppState = useSetAppState();
    const [isError, setIsError] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [isCreateNewAnswererLoading, setIsCreateNewAnswererLoading] =
        useState(false);
    const [isSendVerifyEmailLoading, setIsSendVerifyEmailLoading] =
        useState(false);

    const location = useLocation();

    const answerNameValidated = (
        validationItems: ValidationItems
    ): ValidatedResult => {
        const isError = Boolean(!validationItems.name);
        const errorMessage = isError ? "入力してください" : "";
        const validatedResult: ValidatedResult = {
            isError: isError,
            errorMessage: errorMessage,
        };
        return validatedResult;
    };

    const birthDayValidated = (
        validationItems: ValidationItems
    ): ValidatedResult => {
        if (validationItems.birthDay.length != 8) {
            return {
                isError: true,
                errorMessage: "日付は８桁で入力してください。",
            } as ValidatedResult;
        }

        if (!isDate(validationItems.birthDay)) {
            return {
                isError: true,
                errorMessage: "日付の入力が不正です。",
            } as ValidatedResult;
        }
        return { isError: false, errorMessage: "" } as ValidatedResult;
    };

    const receiverNicknameValidated = (
        validationItems: ValidationItems
    ): ValidatedResult => {
        const isError = Boolean(!validationItems.receiverNickName);
        const errorMessage = isError ? "入力してください" : "";
        const validatedResult: ValidatedResult = {
            isError: isError,
            errorMessage: errorMessage,
        };
        return validatedResult;
    };

    const questionSendTypeValidated = (
        validationItems: ValidationItems
    ): ValidatedResult => {
        const isError =
            validationItems.questionSendType !== "Email" &&
            validationItems.questionSendType !== "Line";
        const errorMessage = isError ? "連絡手段を1つ選択してください。" : "";
        const validatedResult: ValidatedResult = {
            isError: isError,
            errorMessage: errorMessage,
        };
        return validatedResult;
    };

    const answerEmailValidated = (
        validationItems: ValidationItems
    ): ValidatedResult => {
        if (!validationItems.email.match(EMAIL_REGEX)) {
            return {
                isError: true,
                errorMessage: "メールアドレスが不正です。",
            };
        }

        if (
            validationItems.answerers
                .map((x) => x.email)
                .some((email) => email === validationItems.email)
        ) {
            return {
                isError: true,
                errorMessage:
                    "このメールアドレスは、既に別の相手へのメールアドレスとして登録されています。別のメールアドレスのご利用をご検討ください。",
            };
        }

        return {
            isError: false,
            errorMessage: "",
        };
    };

    const qmailSendFrequencyValidated = (
        validationItems: ValidationItems
    ): ValidatedResult => {
        const isError = Boolean(
            validationItems.qmailSendFrequency.length === 0
        );
        const errorMessage = isError ? "最低でも１つは選択してください。" : "";
        const validatedResult: ValidatedResult = {
            isError: isError,
            errorMessage: errorMessage,
        };
        return validatedResult;
    };

    const newAnswererInfoValidated = (
        validationItems: ValidationItems
    ): ValidatedResult => {
        const answerNameResult = answerNameValidated(validationItems);
        if (answerNameResult.isError) return answerNameResult;

        const birthDayResult = birthDayValidated(validationItems);
        if (birthDayResult.isError) return birthDayResult;

        const receiverNicknameResult =
            receiverNicknameValidated(validationItems);
        if (receiverNicknameResult.isError) return receiverNicknameResult;

        const questioncSendTypeResult =
            questionSendTypeValidated(validationItems);
        if (questioncSendTypeResult.isError) return questioncSendTypeResult;

        if (validationItems.questionSendType === "Email") {
            const answerEmailResult = answerEmailValidated(validationItems);
            if (answerEmailResult.isError) return answerEmailResult;
        }

        const qmailSendFrequencyResult =
            qmailSendFrequencyValidated(validationItems);
        if (qmailSendFrequencyResult.isError) return qmailSendFrequencyResult;

        return { isError: false, errorMessage: "" } as ValidatedResult;
    };

    const validated: Map<string, (value: ValidationItems) => ValidatedResult> =
        new Map([
            [Path.answerername, answerNameValidated],
            [Path.answererbirthday, birthDayValidated],
            [Path.receivernickname, receiverNicknameValidated],
            [Path.answererQuestionSendType, questionSendTypeValidated],
            [Path.answereremail, answerEmailValidated],
            [Path.qmailsendfrequency, qmailSendFrequencyValidated],
            [Path.answererconfirm, newAnswererInfoValidated],
        ]);

    useEffect(() => {
        const validator = validated.get(location.pathname);
        if (!validator) return;
        const validationItems: ValidationItems = {
            name: newAnswererInfo.name,
            birthDay: newAnswererInfo.birthday,
            receiverNickName: newAnswererInfo.receiverNickname,
            email: newAnswererInfo.email,
            qmailSendFrequency: newAnswererInfo.qmailSendFrequency,
            questionSendType: newAnswererInfo.questionSendType,
            answerers: newAnswererInfo.answerers,
        };
        const validatedResult = validator(validationItems);
        setIsError(validatedResult.isError);
        setErrorMessage(
            location.pathname === Path.answererconfirm
                ? validatedResult.errorMessage
                : ""
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateAnswererName = (name: string) => {
        const validatedResult = answerNameValidated({
            name: name,
        } as ValidationItems);
        setIsError(validatedResult.isError);
        setErrorMessage(validatedResult.errorMessage);

        const updatedNewAnswererInfo: NewAnswererInfo = {
            ...state.newAnswererInfo,
            name,
        };

        setAppState((state) => ({
            ...state,
            newAnswererInfo: updatedNewAnswererInfo,
        }));
    };

    const updateAnswererRelationship = (relationship: Relationship) => {
        const updatedNewAnswererInfo: NewAnswererInfo = {
            ...state.newAnswererInfo,
            relationship,
        };

        setAppState((state) => ({
            ...state,
            newAnswererInfo: updatedNewAnswererInfo,
        }));
    };

    const updateAnswererBirthDay = (strBirthday: string) => {
        const validatedResult = birthDayValidated({
            birthDay: strBirthday,
        } as ValidationItems);
        setIsError(validatedResult.isError);
        setErrorMessage(validatedResult.errorMessage);

        const updatedNewAnswererInfo: NewAnswererInfo = {
            ...state.newAnswererInfo,
            birthday: strBirthday,
        };

        setAppState((state) => ({
            ...state,
            newAnswererInfo: updatedNewAnswererInfo,
        }));
    };

    const updateReceiverNickname = (receiverNickname: string) => {
        const validatedResult = receiverNicknameValidated({
            receiverNickName: receiverNickname,
        } as ValidationItems);
        setIsError(validatedResult.isError);
        setErrorMessage(validatedResult.errorMessage);

        const updateNewAnswererInfo: NewAnswererInfo = {
            ...state.newAnswererInfo,
            receiverNickname,
        };

        setAppState((state) => ({
            ...state,
            newAnswererInfo: updateNewAnswererInfo,
        }));
    };

    const updateQuestionSendType = (questionSendType: QuestionSendType) => {
        const validatedResult = questionSendTypeValidated({
            questionSendType: questionSendType,
        } as ValidationItems);
        setIsError(validatedResult.isError);
        setErrorMessage(validatedResult.errorMessage);

        const updateNewAnswererInfo: NewAnswererInfo = {
            ...state.newAnswererInfo,
            questionSendType,
        };

        setAppState((state) => ({
            ...state,
            newAnswererInfo: updateNewAnswererInfo,
        }));
    };

    const updateAnswererEmail = (email: string) => {
        const validatedResult = answerEmailValidated({
            email: email,
            answerers: newAnswererInfo.answerers,
        } as ValidationItems);
        setIsError(validatedResult.isError);
        setErrorMessage(validatedResult.errorMessage);

        const updatedNewAnswererInfo: NewAnswererInfo = {
            ...state.newAnswererInfo,
            email,
        };

        setAppState((state) => ({
            ...state,
            newAnswererInfo: updatedNewAnswererInfo,
        }));
    };

    const applyQmailSendFrequency = (qmailSendFrequency: Frequency) => {
        const beforeQmailSendFrequency =
            state.newAnswererInfo.qmailSendFrequency;
        const updatedQmailSendFrequency: Frequency[] =
            beforeQmailSendFrequency.includes(qmailSendFrequency)
                ? beforeQmailSendFrequency.filter(
                      (item) => item !== qmailSendFrequency
                  )
                : beforeQmailSendFrequency.concat([qmailSendFrequency]);

        const updatedNewAnswererInfo: NewAnswererInfo = {
            ...state.newAnswererInfo,
            qmailSendFrequency: updatedQmailSendFrequency,
        };

        setAppState((state) => ({
            ...state,
            newAnswererInfo: updatedNewAnswererInfo,
        }));

        const validatedResult = qmailSendFrequencyValidated({
            qmailSendFrequency: updatedNewAnswererInfo.qmailSendFrequency,
        } as ValidationItems);
        setIsError(validatedResult.isError);
        setErrorMessage(validatedResult.errorMessage);
    };

    const createNewAnswerer = async () => {
        setIsCreateNewAnswererLoading(true);
        try {
            const result = await createAnswerer(
                newAnswererInfo.name,
                newAnswererInfo.receiverNickname,
                newAnswererInfo.questionSendType,
                newAnswererInfo.email,
                toFormatString(parseToDate(newAnswererInfo.birthday), true),
                newAnswererInfo.relationship,
                newAnswererInfo.qmailSendFrequency,
                csrfToken
            );

            const updatedNewAnswererInfo: NewAnswererInfo = {
                ...state.newAnswererInfo,
                answererId: result ? result.answererId : "",
                answerers: [...state.newAnswererInfo.answerers, result],
            };

            setAppState((state) => ({
                ...state,
                newAnswererInfo: updatedNewAnswererInfo,
            }));

            return result;
        } catch {
            const errorMessage =
                "エラーが発生しました。\n管理者にお問い合わせください。";
            displayErrorToastIfError(
                {
                    isError: true,
                    errorMessage: errorMessage,
                },
                "mb-20"
            );
            setIsError(true);
            setErrorMessage(errorMessage);
            return;
        } finally {
            setIsCreateNewAnswererLoading(false);
        }
    };

    const sendVerifyEmail = async (): Promise<boolean> => {
        setIsSendVerifyEmailLoading(true);
        if (!newAnswererInfo.answererId) {
            const errorMessage =
                "予期しないエラーが発生しました。\n管理者にお問い合わせください。";
            displayErrorToastIfError(
                {
                    isError: true,
                    errorMessage: errorMessage,
                },
                "mb-20"
            );
            setIsError(true);
            setErrorMessage(errorMessage);
            setIsSendVerifyEmailLoading(false);
            return false;
        }

        try {
            await sendVerifyEmailToAnswerer(
                newAnswererInfo.answererId,
                csrfToken
            );
            return true;
        } catch {
            const errorMessage =
                "疎通確認メール送信に失敗しました。\n宛先メールアドレスを再度確認してください。";
            displayErrorToastIfError(
                {
                    isError: true,
                    errorMessage: errorMessage,
                },
                "mb-20"
            );
            setIsError(true);
            setErrorMessage(errorMessage);
            return false;
        } finally {
            setIsSendVerifyEmailLoading(false);
        }
    };

    const displayErrorToastIfError = (
        validatedResult: ValidatedResult,
        toastClassName?: string
    ) => {
        if (validatedResult.isError) {
            showToast(validatedResult.errorMessage, "error", {
                className: toastClassName ?? "",
            });
        }
    };

    return {
        isError,
        errorMessage,
        newAnswererInfo,
        updateAnswererName,
        updateAnswererRelationship,
        updateAnswererBirthDay,
        updateReceiverNickname,
        updateAnswererEmail,
        applyQmailSendFrequency,
        updateQuestionSendType,
        createNewAnswerer,
        sendVerifyEmail,
        isCreateNewAnswererLoading,
        isSendVerifyEmailLoading,
    };
};
