import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { AnswerersPage } from "../pages/AnserwersPage";
import { ProfilePage } from "../pages/ProfilePage";
import { SigninPage } from "../pages/SigninPage";
import { Outlet, RouteObject } from "react-router-dom";
import { memo, useEffect, useState } from "react";
import { SignupPage } from "../pages/SignupPage";
import { TutorialPage } from "../pages/Tutorial";
import { AnswererNamePage } from "../pages/AnswererNamePage";
import { AnswererRelationshipPage } from "../pages/AnswererRelationshipPage";
import { HomePage } from "../pages/HomePage";
import { AnswererBirthDayPage } from "../pages/AnswererBirthDayPage";
import { ReceiverNicknamePage } from "../pages/ReceiverNicknamePage";
import { AnswererEmailPage } from "../pages/AnswererEmailPage";
import { useAppState, useSetAppState } from "../stores/appContext";
import { useAuth } from "../features/auth/hooks/useAuth";
import { verifyAuthToken } from "../api/auth";
import { MemoryDetail } from "../pages/MemoryDetail";
import { Layout } from "../components/Layout";
import { SignupResultPage } from "../pages/SignupResultPage";
import { QmailSendFrequencyPage } from "../pages/QmailSendFrequencyPage";
import { AnswererConfirmPage } from "../pages/AnswererConfirmPage";
import { Path } from "./PagePath";
import { AnswererCompletePage } from "../pages/AnswererCompletePage";
import { SenderEmailContentsConfirmPage } from "../pages/SenderEmailContentsConfirmPage";
import { SendEmailCompletedPage } from "../pages/SendEmailCompletedPage";
import { AnswererDetailPage } from "../pages/AnswererDetailPage";
import { AnswererDetailQmailSendFrequencyPage } from "../pages/AnswererDetailQmailSendFrequencyPage";
import { ErrorPage } from "../pages/ErrorPage";
import { useGTM } from "../utils/hooks/useGTM";
import { SendConfirmationCodePage } from "../pages/SendConfirmationCodePage";
import { SendConfirmationCodeCompletedPage } from "../pages/SendConfirmationCodeCompletedPage";
import { NewPasswordPage } from "../pages/NewPasswordPage";
import { NewPasswordCompletedPage } from "../pages/NewPasswordCompletedPage";
import { TermsPage } from "../pages/TermsPage";
import { UseStartMailRemindPage } from "../pages/UseStartMailRemindPage";
import featureFlags from "../../featureFlags.json";
import { EmailConfirmPage } from "../pages/EmailConfirmPage";
import { showToast } from "@twioku/common_components";
import {
    QueryClient,
    QueryClientProvider,
    useQueryClient,
} from "@tanstack/react-query";
import { AnswererQuestionSendTypePage } from "../pages/AnswererQuestionSendTypePage";
import { AnswererLinePreparationPage } from "../pages/AnswererLinePreparationPage";
import { SenderLineLinkConfirmPage } from "../pages/SenderLineLinkConfirmPage";

const LayoutWrappedOutlet = () => {
    return (
        <Layout>
            <Outlet />
        </Layout>
    );
};

const ProtectedRoute: React.FC = () => {
    const [rendered, setRendered] = useState(false);
    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const state = useAppState();
    const { isLoggedIn } = state;
    const setAppState = useSetAppState();
    const { fetchCSRFToken } = useAuth();
    const queryClient = useQueryClient();
    useGTM();

    // FIXME: useAuthの中でやるべき
    useEffect(() => {
        if (!rendered) {
            setRendered(true);
            return;
        }

        if (!isLoggedIn) {
            verifyAuthToken()
                .then((data) => {
                    fetchCSRFToken();
                    setAppState((s) => ({
                        ...s,
                        isLoggedIn: true,
                        cognitoUsername: data.userId,
                    }));
                    console.log("logged in");
                })
                .catch((e) => {
                    console.error(e);
                    showToast("ログインする必要があります。", "error");
                    queryClient.clear();
                    setAppState((s) => ({ ...s, isLoggedIn: false }));
                    navigate({
                        pathname: Path.signin,
                        search: `?redirectTo=${encodeURIComponent(
                            pathname + search
                        )}`,
                    });
                });
        }
    }, [
        rendered,
        fetchCSRFToken,
        isLoggedIn,
        navigate,
        setAppState,
        pathname,
        search,
        queryClient,
    ]);

    return isLoggedIn ? <LayoutWrappedOutlet /> : <></>;
};

const UnProtectedRoute: React.FC = () => {
    return <LayoutWrappedOutlet />;
};

export const ROUTES: RouteObject[] = [
    {
        path: Path.root,
        element: <ProtectedRoute />,
        children: [
            {
                index: true,
                element: <HomePage />,
            },
            {
                path: Path.answerers,
                element: <AnswerersPage />,
            },
            {
                path: Path.profile,
                element: <ProfilePage />,
            },
            {
                path: Path.tutorial,
                element: <TutorialPage />,
            },
            {
                path: Path.answerer,
                children: [
                    {
                        path: Path.answerername,
                        element: <AnswererNamePage />,
                    },
                    {
                        path: Path.answererrelationship,
                        element: <AnswererRelationshipPage />,
                    },
                    {
                        path: Path.answererbirthday,
                        element: <AnswererBirthDayPage />,
                    },
                    {
                        path: Path.receivernickname,
                        element: <ReceiverNicknamePage />,
                    },
                    {
                        path: Path.answererQuestionSendType,
                        element: <AnswererQuestionSendTypePage />,
                    },
                    {
                        path: Path.answereremail,
                        element: <AnswererEmailPage />,
                    },
                    {
                        path: Path.answererLinePreparation,
                        element: <AnswererLinePreparationPage />,
                    },
                    {
                        path: Path.qmailsendfrequency,
                        element: <QmailSendFrequencyPage />,
                    },
                    {
                        path: Path.answererconfirm,
                        element: <AnswererConfirmPage />,
                    },
                    {
                        path: Path.answerercomplete,
                        element: <AnswererCompletePage />,
                    },
                    {
                        path: Path.senderEmailConfirm,
                        element: <SenderEmailContentsConfirmPage />,
                    },
                    {
                        path: Path.senderLineLinkConfirm,
                        element: <SenderLineLinkConfirmPage />,
                    },
                    {
                        path: Path.sendEmailCompleted,
                        element: <SendEmailCompletedPage />,
                    },
                    {
                        path: Path.answererDetail,
                        element: <AnswererDetailPage />,
                    },
                    {
                        path: Path.answererDetailQmailSendFrequency,
                        element: <AnswererDetailQmailSendFrequencyPage />,
                    },
                ],
            },
            {
                path: Path.memorydetail,
                element: <MemoryDetail />,
            },
            {
                path: Path.useStartMailRemind,
                element: <UseStartMailRemindPage />,
            },
            {
                path: Path.emailConfirm,
                element: <EmailConfirmPage />,
            },
        ],
    },
    {
        path: Path.root,
        element: <UnProtectedRoute />,
        children: [
            {
                path: Path.signin,
                element: <SigninPage />,
            },
            ...(featureFlags.signup
                ? [
                      {
                          path: Path.signup,
                          element: <SignupPage />,
                      },
                      {
                          path: Path.signupResult,
                          element: <SignupResultPage />,
                      },
                  ]
                : []),
            {
                path: Path.terms,
                element: <TermsPage />,
            },
            {
                path: Path.error,
                element: <ErrorPage />,
            },
            {
                path: Path.passwordResetSendConfirmationCode,
                element: <SendConfirmationCodePage />,
            },
            {
                path: Path.passwordResetSendConfirmationCodeCompleted,
                element: <SendConfirmationCodeCompletedPage />,
            },
            {
                path: Path.passwordResetNewPassword,
                element: <NewPasswordPage />,
            },
            {
                path: Path.passwordResetNewPasswordCompleted,
                element: <NewPasswordCompletedPage />,
            },
            {
                path: "*", // 404
                element: <ErrorPage errorMessage="ページが見つかりません。" />,
            },
        ],
    },
];

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false,
            staleTime: Infinity,
        },
    },
});

// eslint-disable-next-line react/display-name
export const PageRouter = memo(() => {
    const element = useRoutes(ROUTES);
    return (
        <>
            <QueryClientProvider client={queryClient}>
                {element}
            </QueryClientProvider>
        </>
    );
});
