import React, { forwardRef, useRef } from "react";
import { TextInput } from "@twioku/common_components";
import ImageUploader from "../../../assets/image_uploader.svg";
import Close from "../../../assets/close.svg";
import { PreviewableImage, showToast } from "@twioku/common_components";

type Props = {
    commentText: string;
    imageFiles: Array<File>;
    placeholder?: string;
    className?: string;
    disabled?: boolean;
    handleChangeCommentText: (
        e: React.ChangeEvent<HTMLTextAreaElement>
    ) => void;
    handleClickSendCommentButton: (
        e: React.MouseEvent<HTMLButtonElement>
    ) => void;
    handleChangeAddImageButton: (files: Array<File>) => {
        isSuccess: boolean;
        message: string;
    };
};

// FIXME: コメント入力行が増えるに応じてコメント入力欄の高さを高くしたい。
// 現在は3行分の余白をあけて対応している。
// FIXME: react-hook-form を使う
export const AddCommentForm = forwardRef<HTMLInputElement, Props>(
    (props: Props, ref) => {
        const {
            commentText,
            imageFiles,
            placeholder = "",
            disabled = false,
            handleChangeCommentText,
            handleClickSendCommentButton,
            handleChangeAddImageButton,
        } = props;

        const inputRef = useRef<HTMLInputElement>(null);
        const sendButtonRef = useRef<HTMLButtonElement>(null);
        const handleProfileChangeButtonClick = () => inputRef.current?.click();
        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const fileList = event.target.files ?? ([] as File[]);
            const newImages = Array.from(fileList);

            const { isSuccess, message } = handleChangeAddImageButton([
                ...imageFiles,
                ...newImages,
            ]);
            if (!isSuccess) {
                showToast(message, "error");
            }

            // onChangeイベントを毎回発火させるためにvalueを空文字に設定
            event.target.value = "";
        };
        const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
            if (e.key === "Tab") {
                e.preventDefault();
                sendButtonRef.current?.focus();
            }
        };

        return (
            <div className="mx-4">
                <div>
                    <input
                        type="file"
                        accept="image/jpeg,image/png,image/gif"
                        onChange={handleChange}
                        multiple
                        className="absolute hidden"
                        ref={inputRef}
                    />
                    <TextInput
                        type="text"
                        name="newComment"
                        className="mx-4 h-full w-full rounded-lg border pl-6 pr-4"
                        placeholder={placeholder}
                        value={commentText}
                        multiline
                        maxRows={3}
                        onChange={handleChangeCommentText}
                        onKeyDown={handleKeyDown}
                        ref={ref}
                    />
                </div>
                <div className="my-2 flex items-center justify-between">
                    <div className="flex items-center">
                        {imageFiles.length === 0 && (
                            <button
                                className="flex items-center"
                                onClick={handleProfileChangeButtonClick}
                            >
                                <img src={ImageUploader} alt="ImageUploader" />
                                <p className="ml-1 text-sm text-gray-400">
                                    画像を添付
                                </p>
                            </button>
                        )}
                        {imageFiles.map((file, index) => (
                            <PreviewableImage
                                key={index}
                                className="mr-1 h-12 w-12"
                                src={URL.createObjectURL(file)}
                            />
                        ))}
                        {imageFiles.length !== 0 && (
                            <button
                                className="flex"
                                onClick={() => handleChangeAddImageButton([])}
                            >
                                <img className="h-5" src={Close} alt="Close" />
                                <p className="break-normal text-sm text-gray-400">
                                    削除
                                </p>
                            </button>
                        )}
                    </div>
                    <button
                        ref={sendButtonRef}
                        onClick={handleClickSendCommentButton}
                        className={`gtm-comment-button h-8 w-16 min-w-16 rounded-full px-3 text-base text-white
                            ${
                                disabled ||
                                (commentText.trim().length === 0 &&
                                    imageFiles.length === 0)
                                    ? "pointer-events-none bg-gray-300"
                                    : "bg-primary"
                            }`}
                        disabled={
                            disabled ||
                            (commentText.trim().length === 0 &&
                                imageFiles.length === 0)
                        }
                    >
                        送信
                    </button>
                </div>
            </div>
        );
    }
);

AddCommentForm.displayName = "AddCommentForm";
