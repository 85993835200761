import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@twioku/common_components";
import { useNewAnswererInfo } from "../features/newAnswererInfo/hooks/useNewAnswererInfo";
import { Path } from "../routes/PagePath";
import { AnswererDetail } from "../features/newAnswererInfo/components/AnswererDetail";

export const AnswererConfirmPage: React.FC = () => {
    const navigate = useNavigate();
    const {
        isError,
        newAnswererInfo,
        createNewAnswerer,
        isCreateNewAnswererLoading,
    } = useNewAnswererInfo();

    const handleClick = async () => {
        const res = await createNewAnswerer();
        if (res) navigate(Path.answerercomplete);
    };

    return (
        <div className="flex h-full flex-col">
            <div className="flex-1 overflow-y-auto pb-32">
                <h1 className="mx-auto w-3/4 text-center text-2xl font-bold">
                    {newAnswererInfo.name}さんについて、
                    <br />
                    内容にお間違いないですか？
                </h1>
                <AnswererDetail
                    name={newAnswererInfo.name}
                    birthday={newAnswererInfo.birthday}
                    relationship={newAnswererInfo.relationship}
                    receiverNickname={newAnswererInfo.receiverNickname}
                    questionSendType={newAnswererInfo.questionSendType}
                    email={newAnswererInfo.email}
                    qmailSendFrequency={newAnswererInfo.qmailSendFrequency}
                />
            </div>
            <div className="fixed bottom-0 left-1/2 w-11/12 -translate-x-1/2 bg-white">
                <Button
                    onClick={handleClick}
                    className="mb-8 mt-2 h-12 w-full"
                    disabled={isCreateNewAnswererLoading || isError}
                    loading={isCreateNewAnswererLoading}
                >
                    OK!
                </Button>
            </div>
        </div>
    );
};
