import { useAppState } from "../../../stores/appContext";
import { useFeatureFlags } from "./useFeatureFlag";

export type FeatureFlagName = "signup" | "line";

export const useFeatureEnabled = (
    featureFlagName: FeatureFlagName
): boolean => {
    const featureFlags = useFeatureFlags();
    const state = useAppState();

    if (featureFlagName === "signup") {
        return featureFlags.signup;
    }

    if (featureFlagName === "line") {
        return state.cognitoUsername
            ? featureFlags.line?.allowedUserIds?.includes(state.cognitoUsername)
            : false;
    }

    return false;
};
