import React from "react";
import EditIconImg from "../../../assets/edit_icon.svg";

type Props = {
    label: string;
    value: string;
    handleClickFrequencyEditIcon?: (
        event: React.MouseEvent<HTMLButtonElement>
    ) => void;
    errorMessage: string;
};

export const ConfirmContentFrequency: React.FC<Props> = (props: Props) => {
    const { label, value, handleClickFrequencyEditIcon, errorMessage } = props;
    return (
        <div className="w-full break-words border-b-[1px] border-gray-200 pt-1">
            <div className="px-3">
                <span className="block text-xs text-gray-400">{label}</span>
                <div className="my-2 flex justify-between">
                    <span className="block w-full">{value}</span>
                    {handleClickFrequencyEditIcon && (
                        <button onClick={handleClickFrequencyEditIcon}>
                            <img src={EditIconImg} className="h-5" />
                        </button>
                    )}
                </div>
                <span className="block text-center text-base text-red-500">
                    {errorMessage}
                </span>
            </div>
        </div>
    );
};
