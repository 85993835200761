import { Link } from "react-router-dom";
import { Path } from "../routes/PagePath";

export const NewPasswordCompletedPage = () => {
    return (
        <div className="mx-6">
            <div className="mt-16 flex justify-center">
                パスワードの再設定が完了しました。
                <br />
                変更したパスワードでログインして引き続きTwiokuをお楽しみください。
            </div>
            <div className="mt-3 flex justify-center">
                <Link
                    to={Path.signin}
                    className="text-base text-primary underline"
                >
                    ログイン画面に戻る
                </Link>
            </div>
        </div>
    );
};
