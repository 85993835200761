import { useEffect, useState } from "react";
import { Memory } from "../../../api/generated";
import {
    addCommentOnMemory,
    getMemory,
    incrementMemoryLikedCount,
    uploadCommentImage,
} from "../../../api/memory";
import { useAppState, useSetAppState } from "../../../stores/appContext";
import { useAuth } from "../../auth/hooks/useAuth";
import { Path } from "../../../routes/PagePath";
import { showToast } from "@twioku/common_components";
import { useMutation } from "@tanstack/react-query";
import { useMemories } from "./useMemories";

const initialMemory: Memory = {
    userId: "",
    id: "",
    answererId: "",
    answererName: "",
    respondDate: "",
    questionId: "",
    title: "",
    category: "",
    sendDate: "",
    respondContent: "",
    likedCount: 0,
    comments: [],
    isUnread: false,
    hasUnreadComment: false,
    createAt: "",
    responseStatus: "NOT_ANSWERED_YET",
    explanation: "",
};

// FIXME: 用途ごとに hook を分割し、且つ tanstack query を使う
export const useMemory = (id: string) => {
    const { csrfToken } = useAuth();
    const [memory, setMemory] = useState<Memory>(initialMemory);
    const [newCommentText, setNewCommentText] = useState<string>("");
    const [newCommentImages, setNewCommentImages] = useState<Array<File>>([]);
    const setAppState = useSetAppState();
    const state = useAppState();
    const { tempLikedMemoryIds } = state;
    const [isLoadingAddComment, setIsLoadingAddComment] =
        useState<boolean>(false);
    const [isMemoryLoading, setIsMemoryLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const { memories } = useMemories();
    const { mutate: incrementLikedCountMutate } = useMutation({
        mutationFn: () => incrementLikedCount(),
        onSuccess() {
            const targetMemory = memories.find((x) => x.id === memory.id);
            if (!targetMemory) return;
            targetMemory.likedCount++;
        },
    });

    const { mutate: addCommentMutate } = useMutation({
        mutationFn: () => addComment(),
        onSuccess(data) {
            if (!data) return;
            const memoryIndex = memories.findIndex((x) => x.id === data.id);
            if (memoryIndex < 0) return;
            memories[memoryIndex] = data;
        },
    });

    useEffect(() => {
        if (!id || id.length <= 0) return;
        setIsMemoryLoading(true);
        getMemory(id)
            .then((memory) => {
                setMemory(memory);
            })
            .catch(() => {
                window.location.href = Path.notFound;
            })
            .finally(() => {
                setIsMemoryLoading(false);
            });
    }, [id]);

    useEffect(() => {
        if (!isError) return;

        const timer = setTimeout(() => {
            setIsError(false);
            setErrorMessage("");
        }, 5 * 1000);

        return () => clearTimeout(timer);
    }, [isError, errorMessage]);

    const addComment = async (): Promise<Memory | void> => {
        if (newCommentText.length <= 0 && newCommentImages.length <= 0) return;

        setIsLoadingAddComment(true);

        try {
            const filePaths = await Promise.all(
                newCommentImages.map(async (newCommentImage) =>
                    uploadCommentImage(newCommentImage, csrfToken)
                )
            );
            const memory = await addCommentOnMemory(
                id,
                newCommentText,
                filePaths,
                csrfToken
            );
            setMemory(memory);
            setNewCommentText("");
            setCommentImages([]);

            return memory;
        } catch (error) {
            showToast(
                "処理を正常に実行できませんでした。\n管理者までお問い合わせください。",
                "error"
            );
            console.error(error);
        } finally {
            setIsLoadingAddComment(false);
        }
    };

    const incrementLikedCount = async () => {
        if (tempLikedMemoryIds.includes(memory.id)) return;
        // いいね数を処理終了を待たずにインクリメントする。
        setMemory((prevMemory) => {
            ++prevMemory.likedCount;
            return { ...prevMemory };
        });
        setAppState((state) => ({
            ...state,
            tempLikedMemoryIds: [...state.tempLikedMemoryIds, memory.id],
        }));

        incrementMemoryLikedCount(memory.id, csrfToken)
            .then((memory) => {
                setMemory(memory);
            })
            .catch(() => {
                // 異常終了の場合、いいねインクリメント処理実行前の状態に戻す。
                --memory.likedCount;
                setMemory({ ...memory });
                setAppState((prevState) => {
                    const newTempLikedMemoryIds =
                        prevState.tempLikedMemoryIds.filter(
                            (n) => n !== memory.id
                        );
                    return {
                        ...prevState,
                        tempLikedMemoryIds: [...newTempLikedMemoryIds],
                    };
                });
                showToast(
                    "処理を正常に実行できませんでした。\n管理者までお問い合わせください。",
                    "error"
                );
            });
    };

    const setCommentImages = (
        files: Array<File>
    ): { isSuccess: boolean; message: string } => {
        const imageCountLimit = 4;
        if (files.length > imageCountLimit) {
            return {
                isSuccess: false,
                message: "選択できる画像は最大4枚です。",
            };
        }

        const sizeLimitMb = 6;
        for (let i = 0; i < files.length; i++) {
            const allowExtensions = ".(jpeg|jpg|png|gif)$";
            if (!files[i].name.match(allowExtensions)) {
                return {
                    isSuccess: false,
                    message: "jpeg, jpg, png, gifファイルを選択してください。",
                };
            }

            const sizeLimit = 1024 * 1024 * sizeLimitMb;
            if (files[i].size > sizeLimit) {
                return {
                    isSuccess: false,
                    message: "ファイルサイズは6MB以下にしてください。",
                };
            }
        }

        setNewCommentImages(files);
        return {
            isSuccess: true,
            message: "",
        };
    };

    return {
        memory,
        newCommentText,
        newCommentImages,
        setNewCommentText,
        addComment: addCommentMutate,
        incrementLikedCount: incrementLikedCountMutate,
        setCommentImages,
        tempLikedMemoryIds,
        isLoadingAddComment,
        isMemoryLoading,
    };
};
