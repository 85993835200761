import { SelectBox } from "@twioku/common_components";
import React from "react";

type SelectBoxOption<T> = {
    value: T;
    label: string;
};

type Props<T> = {
    label: string;
    rightContent?: React.ReactNode;
    value: T;
    name: string;
    options: SelectBoxOption<T>[];
    handleSelect: (value: T) => void;
};

export const ConfirmContentSelect = <T extends string>(props: Props<T>) => {
    const { label, rightContent, value, name, options, handleSelect } = props;
    return (
        <div className="w-full break-words border-b-[1px] border-gray-200 pt-1">
            <div className="px-3">
                <span className="block text-xs text-gray-400">{label}</span>
                <div className="flex items-start justify-between gap-2">
                    <SelectBox<T>
                        options={options}
                        value={value}
                        name={name}
                        onChange={handleSelect}
                        className="w-full border-none px-0"
                    />
                    {rightContent}
                </div>
            </div>
        </div>
    );
};
