export const SendConfirmationCodeCompletedPage = () => {
    return (
        <>
            <div className="mx-6 mt-16 flex justify-center">
                ご入力頂いたメールアドレスにメールをお送りしました。
                <br />
                記載されたリンクからパスワードの再設定を行ってください。
            </div>
        </>
    );
};
