import { Frequency } from "../stores/appContext";
import { Relationship } from "../stores/appContext";

export const daysOfWeek: { english: Frequency; japanese: string }[] = [
    { english: "Sunday", japanese: "日曜日" },
    { english: "Monday", japanese: "月曜日" },
    { english: "Tuesday", japanese: "火曜日" },
    { english: "Wednesday", japanese: "水曜日" },
    { english: "Thursday", japanese: "木曜日" },
    { english: "Friday", japanese: "金曜日" },
    { english: "Saturday", japanese: "土曜日" },
];

export const relationships: { value: Relationship; label: string }[] = [
    { value: "father", label: "父親" },
    { value: "mother", label: "母親" },
    { value: "others", label: "その他" },
];

export const EMAIL_REGEX =
    /^([A-Za-z0-9]{1}[A-Za-z0-9_.+-]*)@{1}([A-Za-z0-9_.-]+)\.([A-Za-z0-9]+)$/;
