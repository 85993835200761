import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@twioku/common_components";
import { Frequency } from "../stores/appContext";
import { useNewAnswererInfo } from "../features/newAnswererInfo/hooks/useNewAnswererInfo";
import { Path } from "../routes/PagePath";
import { SendFrequencyForm } from "../features/newAnswererInfo/components/SendFrequencyForm";

// FIXME: react-hook-form を使う
export const QmailSendFrequencyPage: React.FC = () => {
    const navigate = useNavigate();
    const { isError, errorMessage, newAnswererInfo, applyQmailSendFrequency } =
        useNewAnswererInfo();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const seletedSendFrequency = event.currentTarget.value as Frequency;
        applyQmailSendFrequency(seletedSendFrequency);
    };

    return (
        <div className="mx-6 flex h-full flex-col">
            <div className="flex-1 overflow-y-auto pb-32">
                <h1 className="mx-auto my-3 text-center text-2xl font-bold">
                    {newAnswererInfo.name}さんへのメールの
                    <br />
                    送信頻度を設定しましょう!
                </h1>
                <p className="my-4 leading-4 text-primary">
                    問いかけをどのくらいの頻度で
                    <br />
                    送りたいですか？
                </p>
                <SendFrequencyForm
                    handleChange={handleChange}
                    selectedSendFrequency={newAnswererInfo.qmailSendFrequency}
                />
                <span className="block text-center text-base text-red-500">
                    {errorMessage}
                </span>
            </div>
            <div className="fixed bottom-0 left-1/2 w-11/12 -translate-x-1/2 bg-white">
                <Button
                    onClick={() => navigate(Path.answererconfirm)}
                    className="mb-8 mt-2 h-12 w-full"
                    disabled={isError}
                >
                    次へ
                </Button>
            </div>
        </div>
    );
};
