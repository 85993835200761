import React, { useState, useEffect } from "react";
import { Answerer } from "../../../api/generated";
import { LineLinkShare } from "../../../components/LineLinkShare";
import { useCreateLineLinkOneTimePassword } from "../../lineLink/hooks/useLineLink";
import { showToast } from "@twioku/common_components";

type Props = {
    answerer: Answerer;
};

export const LineVerificationAlert: React.FC<Props> = (props: Props) => {
    const { answerer } = props;
    const { createLineLinkOneTimePassword } =
        useCreateLineLinkOneTimePassword(answerer);
    const [lineLinkOneTimePassword, setLineLinkOneTimePassword] =
        useState<string>("");

    useEffect(() => {
        createLineLinkOneTimePassword.mutate(undefined, {
            onSuccess: (lineLinkOneTimePassword) => {
                setLineLinkOneTimePassword(lineLinkOneTimePassword);
            },
            onError: () => {
                showToast(
                    "エラーが発生しました。\n管理者にお問合せください。",
                    "error"
                );
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <div className="mx-3 flex justify-center py-3">
                <div className="mx-2 my-auto inline-block h-8 w-8 rounded-full bg-yellow-100 p-2">
                    <svg
                        className="h-4 w-4 fill-current text-yellow-500"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                    >
                        <path d="M0 0h24v24H0V0z" fill="none" />
                        <path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z" />
                    </svg>
                </div>
                <div className="mr-5">
                    <p className="text-sm">
                        <span className="font-bold">
                            {answerer.name}
                            さんのLINEアカウントにご利用開始案内リンクをお送りください！
                        </span>
                        <br />
                        <span className="text-gray-500">
                            {answerer.name}
                            さんにリンクにアクセスいただき、ご利用案内に同意いただくことで、ツイオクの利用が開始されます。
                        </span>
                    </p>
                </div>
            </div>
            <div className="mx-auto w-11/12 rounded-lg border py-3">
                <div className="mx-auto w-5/6 text-center">
                    <LineLinkShare
                        lineLinkOneTimePassword={lineLinkOneTimePassword}
                    />
                </div>
            </div>
            <h1 className="mx-5 mb-2 mt-3 text-xl font-bold">
                親御さまのご利用開始手順
            </h1>
            <div className="mx-5 mt-3 text-sm text-gray-600">
                <a
                    href={process.env.VITE_LINE_AUTH_FLOW_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary underline"
                >
                    こちら
                </a>
                からご確認ください。
            </div>
        </>
    );
};
