import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import "./index.scss";
import { PageRouter } from "./routes/PageRouter";
import { AppStateProvider } from "./utils/AppStateProvider";
import { Toaster } from "@twioku/common_components";
import "./i18n/configs"; //i18
import * as Sentry from "@sentry/react";

if (process.env.VITE_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.VITE_SENTRY_DSN,
        integrations: [
            new Sentry.Replay({
                // Additional Replay configuration goes in here, for example:
                maskAllText: true,
                blockAllMedia: true,
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <Toaster />
        <HelmetProvider>
            <AppStateProvider>
                <BrowserRouter>
                    <PageRouter />
                </BrowserRouter>
            </AppStateProvider>
        </HelmetProvider>
    </React.StrictMode>
);
