import React from "react";
import { useNavigate } from "react-router-dom";
import FamilyImage from "../assets/undraw_family_vg76.svg";
import { Button } from "@twioku/common_components";
import { Path } from "../routes/PagePath";

export const TutorialPage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <div className="mx-6">
            <div className="mt-12">
                <img
                    className="mx-auto w-3/4"
                    src={FamilyImage}
                    alt="FamilyImage"
                />
                <h1 className="mx-auto mt-8 w-3/4 text-center text-2xl font-bold">
                    記憶を共有したい相手について教えてください。
                </h1>
            </div>
            <div className="fixed bottom-0 left-1/2 w-11/12 -translate-x-1/2 bg-white">
                <Button
                    onClick={() => navigate(Path.answerername)}
                    className="mb-8 mt-2 h-12 w-full"
                >
                    情報入力に進む
                </Button>
            </div>
        </div>
    );
};
