import React from "react";
import { Link } from "react-router-dom";
import { HOMEPAGE_CONTACT_URL } from "../constants/Urls";

type Props = {
    errorMessage?: string;
};

export const ErrorPage: React.FC<Props> = ({
    errorMessage = "エラーが発生しました。",
}) => {
    return (
        <>
            <h1 className="mx-auto my-9 text-center text-2xl font-bold">
                {errorMessage}
            </h1>
            <div className="mx-auto w-9/12">
                <p className="mb-2">
                    {errorMessage}
                    <br />
                    管理者にお問合せください。
                </p>
                <Link to="/" className="text-primary underline">
                    ホームへ戻る
                </Link>
                <p className="mt-6 text-right text-sm">
                    <a
                        href={HOMEPAGE_CONTACT_URL}
                        className="text-primary"
                        target={`_blank`}
                        rel={`noopener noreferrer`}
                    >
                        お問い合わせはこちら &gt;&gt;
                    </a>
                </p>
            </div>
        </>
    );
};
