import { Frequency } from "../stores/appContext";
import { daysOfWeek } from "./constants";

/**
 * 数値のみで構成される日付(string)をDate型にキャストする
 * @param strNumbers
 * @returns
 */
export const parseToDate = (strNumbers: string): Date => {
    const year = strNumbers.substring(0, 4);
    const month = strNumbers.substring(4, 6);
    const day = strNumbers.substring(6, 8);

    return new Date(+year, +month - 1, +day);
};

/**
 * 数値のみで構成される日付(string)の妥当性を判定する
 * @param strNumbers
 * @returns
 */
export const isDate = (strNumbers: string): boolean => {
    const year = strNumbers.substring(0, 4);
    const month = strNumbers.substring(4, 6);
    const day = strNumbers.substring(6, 8);

    const target = parseToDate(strNumbers);

    return (
        target.getFullYear() === +year &&
        target.getMonth() + 1 === +month &&
        target.getDate() === +day
    );
};

export const toFormatString = (
    date: Date,
    isOnlyDate = false,
    separator = "-"
): string => {
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    if (isOnlyDate) return `${year}${separator}${month}${separator}${day}`;

    const hour = date.getHours().toString();
    const minute = date.getMinutes().toString().padStart(2, "0");

    return `${year}${separator}${month}${separator}${day} ${hour}:${minute}`;
};

export const toyyyyMMddFormatString = (date: Date): string => {
    if (isNaN(date.getTime())) {
        return "";
    }

    const year = date.getFullYear().toString();
    const month = ("00" + (date.getMonth() + 1)).slice(-2).toString();
    const day = ("00" + date.getDate()).slice(-2).toString();
    return `${year}${month}${day}`;
};

/**
 * 頻度を日本語に変換して日曜日、月曜日...の順番で並び替えを行う。
 * @param frequency
 * @returns
 */
export const sortFrequencyJapanese = (
    frequency: Frequency[]
): (string | undefined)[] => {
    if (!frequency || frequency.length === 0) return [];

    const frequencyJapanese = frequency.map((item) => {
        return daysOfWeek.find((day) => day.english === item)?.japanese;
    });

    const daysOfWeekJapaneseArray = daysOfWeek.map((x) => x.japanese);
    frequencyJapanese.sort(function sortByDay(
        a: string | undefined,
        b: string | undefined
    ) {
        if (!(a && b)) return -1;
        return (
            daysOfWeekJapaneseArray.indexOf(a) -
            daysOfWeekJapaneseArray.indexOf(b)
        );
    });

    return frequencyJapanese;
};
