import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@twioku/common_components";
import { useNewAnswererInfo } from "../features/newAnswererInfo/hooks/useNewAnswererInfo";
import { Path } from "../routes/PagePath";
import EmailImg from "../assets/email_default.svg";
import LineImg from "../assets/small_line_icon.svg";
import { useFeatureEnabled } from "../features/featureFlag/hooks/useFeatureEnabled";

// FIXME: react-hook-form を使う
export const AnswererQuestionSendTypePage: React.FC = () => {
    const navigate = useNavigate();
    const { newAnswererInfo, updateQuestionSendType, isError, errorMessage } =
        useNewAnswererInfo();
    const enabledLineFeature = useFeatureEnabled("line");

    const emailCheckStyle =
        newAnswererInfo.questionSendType === "Email" ? "border-primary" : "";
    const lineCheckStyle =
        newAnswererInfo.questionSendType === "Line" ? "border-primary" : "";

    const goToNextPage = () => {
        if (newAnswererInfo.questionSendType === "Email") {
            navigate(Path.answereremail);
        }

        if (newAnswererInfo.questionSendType === "Line") {
            navigate(Path.answererLinePreparation);
        }
    };

    return (
        <>
            <div className="px-6">
                <h1 className="mb-8 mt-5 text-center text-xl font-bold">
                    {newAnswererInfo.name}
                    さんへの連絡手段を選んでください。記憶についての質問などはそちらにお送りします。
                </h1>
                <div
                    className={`flex h-12 w-full cursor-pointer items-center rounded-lg border-2 px-2 text-base ${emailCheckStyle}`}
                    onClick={() => updateQuestionSendType("Email")}
                >
                    <img src={EmailImg} className="mr-2" />
                    <span>メール</span>
                </div>
                {enabledLineFeature && (
                    <div
                        className={`mt-3 flex h-12 w-full cursor-pointer items-center rounded-lg border-2 px-2 text-base ${lineCheckStyle}`}
                        onClick={() => updateQuestionSendType("Line")}
                    >
                        <img src={LineImg} className="mr-2" />
                        <span>LINE</span>
                    </div>
                )}
                <p className="mt-2 text-base text-red-400">{errorMessage}</p>
                <div className="fixed bottom-0 left-1/2 w-11/12 -translate-x-1/2 bg-white">
                    <Button
                        onClick={() => goToNextPage()}
                        className="mb-8 mt-2 h-12 w-full"
                        disabled={isError}
                    >
                        次へ
                    </Button>
                </div>
            </div>
        </>
    );
};
