import React from "react";
import { useNavigate } from "react-router-dom";
import { TextInput, Button } from "@twioku/common_components";
import { TextLabel } from "../components/TextLabel";
import { useNewAnswererInfo } from "../features/newAnswererInfo/hooks/useNewAnswererInfo";
import { Path } from "../routes/PagePath";

// FIXME: react-hook-form を使う
export const AnswererEmailPage: React.FC = () => {
    const navigate = useNavigate();
    const { isError, errorMessage, newAnswererInfo, updateAnswererEmail } =
        useNewAnswererInfo();

    return (
        <>
            <div className="fixed bottom-[40%] left-1/2 w-10/12 -translate-x-1/2 bg-white">
                <h1 className="mx-auto w-3/4 text-center text-2xl font-bold">
                    {newAnswererInfo.name}
                    さんとのやりとりに使用するメールアドレスを入力しましょう!
                </h1>
                <div className="mt-8">
                    <TextLabel>やりとりしたい相手のメールアドレス</TextLabel>
                    <TextInput
                        onChange={(e) => updateAnswererEmail(e.target.value)}
                        type="text"
                        value={newAnswererInfo.email}
                        placeholder="ex)xxxx@example.com"
                        errorMessage={errorMessage}
                        className="w-full"
                    />
                </div>
            </div>
            <div className="fixed bottom-0 left-1/2 w-11/12 -translate-x-1/2 bg-white">
                <Button
                    onClick={() => navigate(Path.qmailsendfrequency)}
                    className="mb-8 mt-2 h-12 w-full"
                    disabled={isError}
                >
                    次へ
                </Button>
            </div>
        </>
    );
};
